import React, {useEffect, useState} from 'react';
import {Link as RouterLink, useLocation} from 'react-router-dom';
// Utilities
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

// Material Components
import {AppBar, Box, Hidden, IconButton, Toolbar, Typography} from '@mui/material';
import MyPayments from "../../../../assets/svgs/MyPayments";
import HomeIcon from "../../../../assets/svgs/HomeIcon";
import MyOrders from "../../../../assets/svgs/MyOrders";
import clsx from "clsx";
import {colorIcon} from "../../../../actions";
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles((theme) => ({
    root: {},
    navBar: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        display: 'flex !important',
        maxHeight: "70px"
    }

}));

const BottomBar = ({className, onMobileClose, openMobile, play, ...rest}) => {
    const window = useSelector(state => state?.getWindowSize);
    const dispatch = useDispatch();
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);
    const [button, setButton] = useState(0)

    const handleChange = (e, button, title) => {
        setButton(button);
        dispatch(colorIcon(title));
    }


    return (
        <Hidden lgUp>
            <AppBar color="primary" sx={{top: 'auto', bottom: 0}}
                    className={clsx(classes.root, classes.appBarTransparent, className)}
                    style={{backgroundColor: "#ffffff"}} {...rest}>
                <div className={classes.navBar}>
                    <Toolbar>
                        <Box display="flex"
                             flexDirection="row"
                             style={{
                                 justifyContent: 'space-between',
                             }}
                        >
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={'1'}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: window.color === 'Home' ? "#059492" : ''
                                    }}
                                    component={RouterLink} to={"/"} onClickCapture={play}
                                    onClick={e => handleChange(e, 1, "Home")}>
                                    <HomeIcon style={{color: "black"}}/>
                                    <Typography variant={"body2"}>
                                        Home
                                    </Typography>
                                </IconButton>
                            </Box>
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={'2'}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: window.color === 'Orders' ? "#059492" : ''
                                    }}
                                    component={RouterLink} to={"/orders"} onClickCapture={play}
                                    onClick={e => handleChange(e, 2, 'Orders')}>
                                    <MyOrders/>
                                    <Typography variant={"body2"}>
                                        Orders
                                    </Typography>
                                </IconButton>
                            </Box>
                            <Box component="span" sx={{p: 2}}>
                                <IconButton
                                    id={'5'}
                                    style={{
                                        display: "flex",
                                        flexDirection: 'column',
                                        color: button === 4 ? "#059492" : ''
                                    }}
                                    component={RouterLink} to={"/payments"} onClickCapture={play}
                                    onClick={e => handleChange(e, 4, 'Payments')}>
                                    <MyPayments/>
                                    <Typography variant={"body2"}>
                                        Payments
                                    </Typography>
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                </div>
            </AppBar>
        </Hidden>
    )
}

BottomBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}

BottomBar.defaultProps = {
    onMobileNavOpen: () => {
    }
};
export default BottomBar

