import {reposWebApi, reposWebV2Api} from './customerAPI'
import {refreshToken, SESSION_EXPIRED} from "./sessionAction";
import qs from "querystring";

export const TRANSACTION_DETAIL_SUCCESS = 'TRANSACTION_DETAIL_SUCCESS'
export const TRANSACTION_LOADING = 'TRANSACTION_LOADING'
export const TRANSACTION_DETAIL_LEDGER_SUCCESS = 'TRANSACTION_DETAIL_LEDGER_SUCCESS'
export const DIRECT_TRANSACTION_DETAIL_LEDGER_SUCCESS = 'DIRECT_TRANSACTION_DETAIL_LEDGER_SUCCESS'
export const CLEAR_PAYMENT_DATA = 'CLEAR_PAYMENT_DATA'
export const PAYMENT_DETAIL = 'PAYMENT_DETAIL'

let token = ''

export const getTransactions = (formData, setIs_Loader, url) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        // dispatch({
        //     type: TRANSACTION_LOADING,
        // });
        setIs_Loader(true);
        reposWebV2Api.get(url, {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token,
                'Content-Type': 'application/json',
            },
            signal: signal,
            params: formData
        }).then(response => {
            dispatch({
                type: TRANSACTION_DETAIL_SUCCESS,
                payload: response?.data?.data
            });
            setIs_Loader(false)
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                dispatch(refreshToken(getTransactions(formData, setIs_Loader, url)))
            }
            setIs_Loader(false);
        })
    }
};


// ----------------- API for transactions ledger -------------------------------------
export const getTransactionsLedger = (formData, setIs_Loader, url) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        // dispatch({
        //     type: TRANSACTION_LOADING,
        // });
        setIs_Loader(true)
        reposWebV2Api.get(url, {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: formData,
        }).then(response => {
            dispatch({
                type: TRANSACTION_DETAIL_LEDGER_SUCCESS,
                payload: response?.data?.data
            });
            setIs_Loader(false);
        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                setIs_Loader(false);
                dispatch(refreshToken(getTransactionsLedger(formData, setIs_Loader)))
                // dispatch(getTransactionsLedger(formData, setIs_Loader))
            }
        })
    }
};


export const get_ledger_details = (formData, enqueueSnackbar, handleClose, url) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        // dispatch({
        //     type: TRANSACTION_LOADING,
        // });
        reposWebV2Api.get(url, {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: formData,
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
            handleClose();
        }).catch(error => {
            if (error?.response?.status === 500) {
                enqueueSnackbar('No data found', {
                    variant: 'error',
                    horizontal: 'center'
                });
            }
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                dispatch(refreshToken(get_ledger_details(formData, enqueueSnackbar)))
                // dispatch(get_ledger_details(formData, enqueueSnackbar))
            }
        })
    }
};

export const add_transaction = (formData, enqueueSnackbar, handleClose) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        // dispatch({
        //     type: TRANSACTION_LOADING,
        // });
        reposWebV2Api.post('/partner/add_transaction_direct_mode_customer', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
            handleClose();
        }).catch(error => {
            if (error?.response?.status === 500) {
                enqueueSnackbar('No data found', {
                    variant: 'error',
                    horizontal: 'center'
                });
            }
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                dispatch(refreshToken(add_transaction(formData, enqueueSnackbar, handleClose)))
                // dispatch(get_ledger_details(formData, enqueueSnackbar))
            }
        })
    }
};

export const clearPaymentData = () => {

    return (dispatch) => {
        dispatch({
            type: CLEAR_PAYMENT_DATA,
        })
    }

};


export const paymentReminder = (data, enqueueSnackbar, handleClose) => {


    return (dispatch) => {

        reposWebApi.post('/utilities/send_reminder_to_customer_partner', qs.stringify(data), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
            handleClose();
        }).catch(error => {
            if (error?.response?.status === 500) {
                enqueueSnackbar('No data found', {
                    variant: 'error',
                    horizontal: 'center'
                });
            }
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                dispatch(refreshToken(paymentReminder(data, enqueueSnackbar)))
            }
        })
    }
};

export const startLoading = () => {

    return (dispatch) => {
        dispatch({
            type: TRANSACTION_LOADING,
        })
    }

};

export const paymentDetails = (formData) => {

    return (dispatch) => {
        // dispatch({
        //     type: TRANSACTION_LOADING,
        // });
        reposWebV2Api.get('/partner/get_payment_detail_partner', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: formData,
        }).then(response => {

            dispatch({
                type: PAYMENT_DETAIL,
                payload: response?.data?.data
            })

        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                dispatch(refreshToken(paymentDetails(formData)))
                // dispatch(get_ledger_details(formData, enqueueSnackbar))
            }
        })
    }

};






