import React, {useEffect, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom';

import {AppBar, Box, Drawer, Hidden, IconButton, Toolbar, Typography} from "@mui/material";

import Logo from '../../../../components/Logo';
import {THEMES} from '../../../../costants';

import {makeStyles} from '@mui/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Profile from './Profile';
import Help from './Help';
import {details} from "../../../../actions";
import {useDispatch, useSelector} from "react-redux";
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import Sidebar from "./Sidebar";


const useStyles = makeStyles((theme) => ({
    root: {
        zIndex: theme.zIndex.drawer + 100,
        ...(theme.name === THEMES.LIGHT
            ? {
                boxShadow: 'none',
                backgroundColor: theme.palette.primary.main
            }
            : {}),
        ...(theme.name === THEMES.ONE_DARK
            ? {
                backgroundColor: theme.palette.background.default
            }
            : {})
    },
    toolbar: {
        minHeight: 64
    },
    appBarTransparent: {
        // backgroundColor:'rgba(67,129,168,0.5)'
    },
    button: {
        backgroundColor: "#02A29F",
        borderRadius: "5px",
        color: "#f5f5f5",
        fontSize: "1rem",
        textTransform: "none",
        padding: "8px 16px",
        width: "117px",
        height: "40px",
        gap: "10px"
    },
    desktopDrawer: {
        width: 320,
        top: 64,
        height: 'calc(100% - 64px)',

    },
}));


const TopBar = ({className, onMobileNavOpen, ...rest}) => {
    const dispatch = useDispatch()
    // const getAllOrder = useSelector(state => state?.getOrder);
    const networkError = useSelector(state => state?.getNotify);
    const rate = useSelector(state => state?.getUpateRate);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        dispatch(details());
    }, [dispatch, rate?.rateCall])


    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    // const handleLayoutChange = () => {
    //     dispatch(layoutAction(true))
    // }
    //
    // const logoutUser = () => {
    //     dispatch(logout());
    //     window.location.reload();
    // };

    const handleOpen = (value) => {
        setOpen(!value)
    }

    const handleClose = () => {
        setOpen(false)
    };

    const classes = useStyles();

    return (
        <AppBar className={clsx(classes.root, classes.appBarTransparent, className)}
                style={{backgroundColor: networkError?.IsNetworkError ? "rgba(225,87,87,0.99)" : "#ffffff"}} {...rest}>
            <Toolbar className={classes.toolbar}>
                <Hidden mdUp>
                    <IconButton color="inherit" onClick={() => handleOpen(open)}>
                        <MenuSharpIcon fontSize={"large"} color={"primary"}/>
                    </IconButton>
                </Hidden>
                {/*<Hidden mdDown>*/}
                {/*    <RouterLink to="/">*/}
                {/*        <Logo/>*/}
                {/*    </RouterLink>*/}
                {/*</Hidden>*/}
                <Box display={"flex"} flexDirection={"column"}>
                    <RouterLink to="/dashboard">
                        <Logo/>
                    </RouterLink>
                </Box>
                <Hidden mdDown>
                    <Box ml={2} flexGrow={1}/>
                    {networkError?.IsNetworkError && <Box>
                        <Typography variant={"body"} color={"#f8f6f6"}>
                            No Internet
                        </Typography>
                    </Box>}
                    <Help/>
                    {/*<Notification/>*/}
                    <Profile/>
                </Hidden>
            </Toolbar>

            <Drawer
                anchor={"left"}
                variant="persistent"
                open={open}
                onClose={handleClose}
                classes={{paper: classes.desktopDrawer}}
            >
                <Sidebar handleClose={handleClose}/>
            </Drawer>
        </AppBar>
    )
}
TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func
};

TopBar.defaultProps = {
    onMobileNavOpen: () => {
    }
};
export default TopBar