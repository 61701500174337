import React, {createContext, useEffect, useReducer} from 'react';
import SplashScreen from '../components/SplashScreen';
import {client_id, client_secret, reposAndroidApi, reposWebApi} from '../config';
import * as qs from 'querystring';
import axios from "../utils/axios";

const initialAuthState = {
    isAuthenticated: false,
    isInitialised: false,
    user: null
};

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    } else {
        return true;
    }
};

const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
        localStorage.removeItem('accessToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INITIALISE': {
            const {isAuthenticated, user} = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialised: true,
                user
            };
        }
        case 'LOGIN': {
            const {user} = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'DETAILS': {
            const {user} = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        }
        case 'REGISTER': {
            const {user} = action.payload;

            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        default: {
            return {...state};
        }
    }
};

const AuthContext = createContext({
    ...initialAuthState,
    method: 'JWT',
    login: () => Promise.resolve(),
    details: () => Promise.resolve(),
    logout: () => {
    },
    register: () => Promise.resolve(),
    loginWithOtp: () => Promise.resolve()
});

export const AuthProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialAuthState);
    const login = async (email, password, otpLogin) => {
        const config = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            }
        };

        let requestBody = {};

        function validate() {
            var text = email;
            var regx = /^[1-9]{1}[0-9]{9}$/;
            const isMobile = regx.test(text);
            if (!isMobile) {
                requestBody = {
                    email: email,
                    password: password,
                    mobile: email,
                    is_email: '1',
                    is_username: '0',
                    grant_type: 'password',
                    client_id: client_id,
                    client_secret: client_secret
                };
            } else {
                requestBody = {
                    mobile: email,
                    password,
                    // TEMP
                    // is_email: '1',
                    // is_email: '0',
                    // is_username: '0',
                    grant_type: 'password',
                    client_id: client_id,
                    client_secret: client_secret
                };
            }
        }

        validate();

        const response = await reposAndroidApi.post(
            '/partner/login',
            qs.stringify(requestBody),
            config
        );

        const accessToken = response?.data?.access_token;
        localStorage.setItem('accessToken', accessToken);
        const userDetails = await reposAndroidApi.get('/partner/partner/detail/', {
            headers: {
                Authorization: 'Bearer ' + localStorage.accessToken
            }
        });

        const user = userDetails.data.data;

        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('density', user.fuel_density);
        localStorage.setItem('rate', user.current_fuel_rate);

        setSession(accessToken);

        // Here you should extract the complete user profile to make it available in your entire app.
        // The auth state only provides basic information.

        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
        // }
    };
    const details = async () => {

        const userDetails = await reposAndroidApi.get('/partner/partner/detail/', {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('accessToken')
            }
        });

        const user = userDetails.data.data;

        dispatch({
            type: 'DETAILS',
            payload: {
                user
            }
        });

    };

    const logout = () => {
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.accessToken
            }
        };

        var requestBody = {
            fcm_token: localStorage.fcmToken
        };

        reposWebApi
            .post('/clear_fcm_token', qs.stringify(requestBody), config)
            .then((res) => {
            })
            .catch((error) => {
            });

        localStorage.clear();
        setSession(null);
        dispatch({type: 'LOGOUT'});
    };

    const register = async (email, name, password) => {
        const response = await axios.post('/api/account/register', {
            email,
            name,
            password
        });
        const {accessToken, user} = response?.data;

        window.localStorage.setItem('accessToken', accessToken);

        dispatch({
            type: 'REGISTER',
            payload: {
                user
            }
        });
    };

    useEffect(() => {
        const initialise = async () => {
            try {
                const accessToken = window.localStorage.getItem('accessToken');

                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);
                    if (window.localStorage.getItem('user')) {
                        const user = JSON.parse(localStorage.getItem('user'));
                        dispatch({
                            type: 'INITIALISE',
                            payload: {
                                isAuthenticated: true,
                                user
                            }
                        });
                    } else {
                        const response = await reposWebApi.get('/partner/partner/detail/', {
                            headers: {
                                Authorization: 'Bearer ' + accessToken
                            }
                        });
                        const user = response?.data?.data;

                        dispatch({
                            type: 'INITIALISE',
                            payload: {
                                isAuthenticated: true,
                                user
                            }
                        });
                    }

                } else {
                    dispatch({
                        type: 'INITIALISE',
                        payload: {
                            isAuthenticated: false,
                            user: null
                        }
                    });
                }
            } catch (err) {
                dispatch({
                    type: 'INITIALISE',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        };

        initialise();
    }, []);

    if (!state.isInitialised) {
        return <SplashScreen/>;
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: 'JWT',
                login,
                logout,
                register,
                details
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;