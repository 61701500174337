import {reposWebApi} from "./customerAPI";
import qs from "querystring";
import {refreshToken} from "./sessionAction";

export const GET_REFUELERS = 'GET_ALL_REFUELLERS_FOR_ORDER';
export const GET_CONVENIENCE = 'GET_CONVENIENCE_CHARGES_FOR_ORDER';
export const GET_CONVENIENCE_FAIL = 'GET_CONVENIENCE_CHARGES_FOR_ORDER_FAIL';
export const PLACE_ORDER = 'PLACE_ORDER';
export const PLACE_ORDER_FAIL = 'PLACE_ORDER_FAIL';
export const ORDER_ALREADY_PRESENT = 'ORDER_ALREADY_PRESENT';
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS';
export const GET_ALL_DIRECT_ORDERS = 'GET_ALL_DIRECT_ORDERS';
export const ORDER_LOADING = 'LOADING';
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER';
export const GET_ALL_ORDERS_FAIL = 'GET_ALL_ORDERS_FAIL';
export const GET_PAST_ORDER = 'GET_PAST_ORDER';
export const GET_ONGOING_ORDER = 'GET_ONGOING_ORDER';
export const GET_OVERDUE_ORDER = 'GET_OVERDUE_ORDER';
export const GET_CLEAR_ORDER = 'GET_CLEAR_ORDER';
// export const CLEAR_ALL_DATA = 'CLEAR_ALL_DATA';
export const GET_ALL_CUSTOMER = 'GET_ALL_CUSTOMER';
export const GET_CUSTOMER = 'GET_CUSTOMER';
//export const SESSION_EXPIRED = 'SESSION_EXPIRED';

let token = ''

export const getAllRefuelers = (formData) => {
    let queryParams = {};
    queryParams.asset_id = formData.asset;
    queryParams.quantity = formData.quantity;

    return (dispatch) => {
        reposWebApi.get('/refueler/get_refuelers_for_order', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: queryParams
        })
            .then(response => {
                if (response?.status === 200) {
                    dispatch({
                        type: GET_REFUELERS,
                        payload: response?.data?.data
                    });
                }
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getAllRefuelers(formData)))
                    // dispatch(getAllRefuelers())
                }
            });
    }
};

export const getOrderAcceptReject = (formData, enqueueSnackbar, setClose, setOpenDialog, setLoading, callBack, currentTab, direct) => {

    return (dispatch) => {
        reposWebApi.post(direct ? "/order/directModeOrderAcceptReject" : '/order/partner_order/accept/reject', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                if (response?.status === 200) {
                    enqueueSnackbar(response?.data?.data, {
                        variant: 'success',
                        horizontal: 'center'
                    });
                    setOpenDialog(false);
                    setClose();
                    setLoading(false);
                    callBack();
                }
                if (response?.status === 201) {
                    enqueueSnackbar(response?.data?.data, {
                        variant: 'error',
                        horizontal: 'center'
                    })
                    setOpenDialog(false);
                    setClose();
                    setLoading(false);
                    callBack();
                }
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getOrderAcceptReject(formData, enqueueSnackbar, setClose, setOpenDialog, setLoading, callBack, currentTab)))
                    // dispatch(getOrderAcceptReject())
                }
                setLoading(false);
            });
    }
};

export const getDirectOrderAcceptReject = (formData, enqueueSnackbar, setClose, setOpenDialog, setLoading, callBack, currentTab) => {

    return (dispatch) => {
        reposWebApi.post("/order/directModeOrderAcceptReject", qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                if (response?.status === 200) {
                    enqueueSnackbar(response?.data?.data, {
                        variant: 'success',
                        horizontal: 'center'
                    });
                    setOpenDialog(false);
                    setClose();
                    setLoading(false);
                    callBack();
                }
                if (response?.status === 201) {
                    enqueueSnackbar(response?.data?.data, {
                        variant: 'error',
                        horizontal: 'center'
                    })
                    setOpenDialog(false);
                    setClose();
                    setLoading(false);
                    callBack();
                }
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getDirectOrderAcceptReject(formData, enqueueSnackbar, setClose, setOpenDialog, setLoading, callBack, currentTab)))
                    // dispatch(getOrderAcceptReject())
                }
                setLoading(false);
            });
    }
};


export const getOrderReview = (orderData) => {

    const requestBody = {
        refueler_id: orderData.refueler,
        asset_id: orderData.asset,
        quantity: orderData.quantity,
    };


    return (dispatch) => {
        reposWebApi.post('/order/process_order', qs.stringify(requestBody), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: GET_CONVENIENCE,
                    payload: response?.data?.data
                });
            })
            .catch(async error => {
                if (error?.response?.status === 403) {
                    dispatch({
                        type: ORDER_ALREADY_PRESENT,
                    })
                }
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getOrderReview(orderData)))
                    // dispatch(getOrderReview())
                } else dispatch({
                    type: GET_CONVENIENCE_FAIL,
                    payload: error
                });
            })
    }
};


export const getOrderDetailList = (param, setIs_Loader) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        setIs_Loader(true);
        // const requestBody = {
        //     start_date:param?.start_date,
        //     end_date:param?.end_date,
        //     limit: !!param?.limit ? param?.limit : 10,
        //     offset: !!param?.offset ? param?.offset : 0,
        //     order_type: order
        // };
        reposWebApi.get('/order/get_upcoming_orders_for_partner_new/', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: param
        })
            .then(response => {
                console.log(response?.data?.data);
                dispatch({
                    type: GET_ALL_ORDERS,
                    payload: response?.data?.data
                })
                setIs_Loader(false);
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getOrderDetailList(param, setIs_Loader)))
                    //dispatch(getAllOrders())
                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
                setIs_Loader(false);
            })
    }
};

export const getOrderDirectMode = (param, setIs_Loader) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        setIs_Loader(true);

        reposWebApi.get('/order/getDirectModeOrders', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: param
        })
            .then(response => {
                dispatch({
                    type: GET_ALL_DIRECT_ORDERS,
                    payload: response?.data?.data
                })
                setIs_Loader(false);
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getOrderDirectMode(param, setIs_Loader)))
                    //dispatch(getAllOrders())
                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
                setIs_Loader(false);
            })
    }
};

export const getPastOrderDetailList = (param, setIs_Loader) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        setIs_Loader(true)
        reposWebApi.get('/order/get_past_orders_for_partner_new/', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: param,
            signal: signal,
        })
            .then(response => {
                dispatch({
                    type: GET_ALL_ORDERS,
                    payload: response?.data?.data
                })
                setIs_Loader(false)
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getPastOrderDetailList(param, setIs_Loader)))
                    setIs_Loader(false)
                    //dispatch(getCurrentOrder())
                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
                setIs_Loader(false)
            })
    }

};

export const getSingleOrder = (data) => {
    return (dispatch) => {
        dispatch({
            type: GET_SINGLE_ORDER,
            payload: data
        })
    }

};

export const loadingStart = () => {
    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
        })
    }

};

export const getClearOrder = (data) => {
    return (dispatch) => {
        dispatch({
            type: GET_CLEAR_ORDER,
            payload: data
        })
    }

};

export const get_single_order_detail = (param) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
        })
        reposWebApi.get('/order/get_single_order_detail/', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: param
        })
            .then(response => {
                dispatch({
                    type: GET_SINGLE_ORDER,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(get_single_order_detail(param)))
                    //dispatch(getCurrentOrder())
                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }

};

export const get_order_tracking = (param) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_LOADING,
        })
        reposWebApi.get('/order/get_order_tracking_detail_new/', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: param
        })
            .then(response => {
                dispatch({
                    type: GET_SINGLE_ORDER,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(get_order_tracking(param)))
                    //dispatch(getCurrentOrder())
                } else dispatch({
                    type: GET_ALL_ORDERS_FAIL,
                    payload: error
                })
            })
    }

};

export const send_order_invoice = (param, enqueueSnackbar) => {
    return (dispatch) => {
        reposWebApi.post('/order/mail/share_proforma_invoice', qs.stringify(param), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            enqueueSnackbar(response?.data.data, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
            });
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(send_order_invoice(param, enqueueSnackbar)))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
            enqueueSnackbar("Something Went Wrong Please...! Try Again later", {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
            });
        })
    }

};

export const directModeCustomers = () => {
    return (dispatch) => {
        reposWebApi.get('/partner/getDirectModeCustomer', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {

            dispatch({
                type: GET_ALL_CUSTOMER,
                payload: response?.data
            })

        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(directModeCustomers()))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
        })
    }

};

export const mailTaxInvoice = (params, enqueueSnackbar) => {
    return (dispatch) => {
        reposWebApi.post('/order/mail/invoice_partner', qs.stringify(params), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            })
        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(mailTaxInvoice(params, enqueueSnackbar)))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
        })
    }

};

export const directModeCustomerDetail = (param) => {
    return (dispatch) => {
        reposWebApi.get('/partner/get_direct_mode_customer_details', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: param
        }).then(response => {

            dispatch({
                type: GET_CUSTOMER,
                payload: response?.data
            })

        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(directModeCustomerDetail()))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
        })
    }

};

export const editDirectModeOrder = (param, enqueueSnackbar, setLoading, handleClose) => {
    setLoading(true);
    return (dispatch) => {
        reposWebApi.post('/order/edit_direct_mode_order', qs.stringify(param), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });

            setLoading(false);
            handleClose()

        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(directModeCustomerDetail()))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
            enqueueSnackbar("Something Went Wrong Please...! Try Again later", {
                variant: 'error',
                horizontal: 'center'
            });
            setLoading(false);
        })
    }

};


export const reviewCustomerDetail = (param) => {
    return (dispatch) => {
        reposWebApi.get('/order/get_order_review', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: param
        }).then(response => {

            dispatch({
                type: GET_CUSTOMER,
                payload: response?.data
            })

        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(reviewCustomerDetail()))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
        })
    }

};

export const placeDirectModeOrder = (param, enqueueSnackbar, setIsLoading, setPlaceOrder, setNextPage) => {
    setIsLoading(true);

    return (dispatch) => {
        reposWebApi.post('/order/place_direct_mode_order', qs.stringify(param), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            enqueueSnackbar(response?.data?.data?.msg, {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
            });

            setIsLoading(false);
            setPlaceOrder(false);
            setNextPage(false);

        }).catch(async error => {
            if (error?.response?.status === 401) {
                await dispatch(refreshToken(placeDirectModeOrder(param, enqueueSnackbar)))
                // dispatch(send_order_invoice(param, enqueueSnackbar))
            }
            enqueueSnackbar("Something Went Wrong Please...! Try Again later", {
                variant: 'error', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                },
            });
            setIsLoading(false);
        })
    }

};

// export const clearData = () => {
//
//     return (dispatch) => {
//         dispatch({
//             type: CLEAR_ALL_DATA,
//         })
//     }
//
// };