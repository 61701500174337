import * as actionTypes from '../actions';

var initialState = {
    sessionExpired: false,
    orderLoading: false,
    dispenseReports: [],
    dispenseDirectReports: [],
    createDispenseEntry: null,
    refillReports: [],
    dataCount: null,
    next: null,
    directnext: null,
    error: false,
    isLoading: true,
    is_dispense_loading: true
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const dispenseReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_DISPENSE_REPORTS: {
            return {
                ...state,
                isLoading: false,
                error: false,
                assetModified: true,
                dispenseReports: getUnique(state.dispenseReports.concat(action?.payload?.results), 'id'),
                // dispenseReports:action?.payload,
                dataCount: action?.payload?.results?.length,
                next: action?.payload?.next,
                sessionExpired: false,
            };
        }

        case actionTypes.GET_DISPENSE_DIRECT_REPORTS: {
            return {
                ...state,
                isLoading: false,
                error: false,
                assetModified: true,
                dispenseDirectReports: getUnique(state.dispenseDirectReports.concat(action?.payload?.results), 'id'),
                // dispenseDirectReports: action?.payload?.results,
                dataCount: action?.payload?.results?.length,
                directnext: action?.payload?.next,
                sessionExpired: false,
            };
        }
        case actionTypes.IS_REPORT: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actionTypes.IS_DISPENSE_LOADING: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actionTypes.CLEAR_DISPENSE_DATA: {
            return {
                ...state,
                isLoading: true,
                dispenseDirectReports: [],
                dispenseReports: [],
            }
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default dispenseReducer;
