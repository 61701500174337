import './App.css';
import {createBrowserHistory} from "history";
import routes, {renderRoutes} from './Routes';
import {Button, GlobalStyles, StyledEngineProvider} from "@mui/material";
import {SnackbarProvider, useSnackbar} from "notistack";
import {ThemeProvider} from "@mui/material/styles";
import ScrollReset from "./components/ScrollReset";
import useSettings from "./hooks/useSettings";
import {Provider as StoreProvider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom'
import {createMuiTheme} from "./theme";
import {configureStore} from "./store";
import {BreakpointProvider} from 'react-socks'

const history = createBrowserHistory();
const store = configureStore();

function App() {
    const {settings} = useSettings();
    const theme = createMuiTheme({
        direction: settings.direction,
        responsiveFontSizes: settings.responsiveFontSizes,
        theme: settings.theme,
    });
    const DismissAction = ({id}) => {
        const {closeSnackbar} = useSnackbar()
        return (
            <>
                {/*<Button onClick={() => alert(`Show more data for key: ${id}`)}>More</Button>*/}
                <Button style={{
                    color: "#FFF"
                }}
                        styleType="link" onClick={() => closeSnackbar(id)}>X</Button>
            </>
        )
    }
    return (
        <BreakpointProvider>
            <StoreProvider store={store}>
                <ThemeProvider theme={theme}>
                    <StyledEngineProvider injectFirst>
                        <SnackbarProvider hideIconVariant dense maxSnack={3} action={key => <DismissAction id={key}/>}>
                            <BrowserRouter history={history}>
                                {/*<AuthProvider>*/}
                                <GlobalStyles/>
                                <ScrollReset/>
                                {renderRoutes(routes)}
                                {/*</AuthProvider>*/}
                            </BrowserRouter>
                        </SnackbarProvider>
                    </StyledEngineProvider>
                </ThemeProvider>
            </StoreProvider>
        </BreakpointProvider>
    );
}

export default App;
