import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const HomeIcon = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);

    return (
        <svg
            width="22"
            height="20"
            viewBox="0 0 15 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={style}
            className={className}
        >
            <path
                d="M9.27273 13.793V10.2475C9.27273 10.0908 9.21047 9.94052 9.09965 9.8297C8.98884 9.71889 8.83854 9.65663 8.68182 9.65663H6.31818C6.16146 9.65663 6.01116 9.71889 5.90035 9.8297C5.78953 9.94052 5.72727 10.0908 5.72727 10.2475V13.793C5.72727 13.9497 5.66502 14.1 5.5542 14.2108C5.44338 14.3216 5.29308 14.3839 5.13636 14.3839H1.59091C1.43419 14.3839 1.28389 14.3216 1.17307 14.2108C1.06226 14.1 1 13.9497 1 13.793V6.96061C1.00132 6.87883 1.019 6.79814 1.05198 6.7233C1.08496 6.64846 1.13258 6.58097 1.19205 6.52481L7.10114 1.15493C7.21007 1.05527 7.35236 1 7.5 1C7.64764 1 7.78993 1.05527 7.89886 1.15493L13.808 6.52481C13.8674 6.58097 13.915 6.64846 13.948 6.7233C13.981 6.79814 13.9987 6.87883 14 6.96061V13.793C14 13.9497 13.9377 14.1 13.8269 14.2108C13.7161 14.3216 13.5658 14.3839 13.4091 14.3839H9.86364C9.70692 14.3839 9.55662 14.3216 9.4458 14.2108C9.33498 14.1 9.27273 13.9497 9.27273 13.793Z"
                stroke={window.color === 'Home' ? "#059492" : "#737373"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    )
}

HomeIcon.propTypes = {
    fill: PropTypes.string
};

export default HomeIcon
