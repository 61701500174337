import {reposWebApi, reposWebV2Api} from './customerAPI'
import {refreshToken, SESSION_EXPIRED} from "./sessionAction";
import qs from "querystring";

export const SAVE_DETAIL = 'SAVE_DETAIL'
export const GET_CREDIT_CUSTOMER = 'GET_CREDIT_CUSTOMER'
export const GET_CREDIT_DIRECT_CUSTOMER = 'GET_CREDIT_DIRECT_CUSTOMER'
export const CREATE_CREDIT_CUSTOMER = 'CRERAT_CREDIT_CUSTOMER'
export const UPDATE_CREDIT_CUSTOMER = 'UPDATE_CREDIT_CUSTOMER'
export const IS_CUSTOMER_LOADING = 'IS_CUSTOMER_LOADING'
export const IS_DETAIL_LOADING = 'IS_DETAIL_LOADING'
export const GET_CUSTOMER_DETAILS = 'GET_CUSTOMER_DETAILS'
export const ERROR_CALL = 'ERROR_CALL'
export const CLEAR_DATA = 'CLEAR_DATA'
export const ASSET_NOT_CREATED = 'ASSET_NOT_CREATED'
export const DELETE_CREDIT_CUSTOMER = 'DELETE_CREDIT_CUSTOMER'
export const DELETE_CUSTOMER_LOADING = 'DELETE_CUSTOMER_LOADING'
export const CLEAR_ERROR = 'CLEAR_ERROR'

let token = ''

export const getCreditCustomer = (formData, fetchMoreData) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return async (dispatch) => {
        // dispatch({
        //     type: IS_CUSTOMER_LOADING,
        // });
        if (!fetchMoreData) {
            dispatch({
                type: IS_CUSTOMER_LOADING,
            });
            dispatch(clear_Data())
        }
        reposWebV2Api.get('/partner/get_credit_customer', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: formData
        }).then(response => {
            dispatch({
                type: GET_CREDIT_CUSTOMER,
                payload: response?.data?.data
            });

        })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
            })
    }
};

export const getDirectCreditCustomer = (formData, fetchMoreData) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return async (dispatch) => {
        // dispatch({
        //     type: IS_CUSTOMER_LOADING,
        // });
        if (!fetchMoreData) {
            dispatch({
                type: IS_CUSTOMER_LOADING,
            });
            dispatch(clear_Data())
        }
        reposWebV2Api.get('/partner/getDirectModeCustomer', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: formData
        }).then(response => {
            dispatch({
                type: GET_CREDIT_DIRECT_CUSTOMER,
                payload: response?.data?.data
            });

        })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
            })
    }
};

export const addDirectCreditCustomer = (data, router, id, enqueueSnackbar, handleClose, HandleCallBack) => {
    console.log(id);
    let formData = {
        "name": data?.customer_name,
        "mobile_number": data?.mobile,
        "company_name": data?.company_name,
        "email": data?.email,
        "gst_number": data?.gst_number,
        "pan_card": data?.pan_number,
        "unique_id": id
    }

    return (dispatch) => {

        if (id) {
            reposWebV2Api.post('/partner/edit_customer_profile_direct_mode', qs.stringify(formData), {
                headers: {
                    Authorization: 'Bearer ' + localStorage.access_token
                },
            }).then(response => {
                dispatch({
                    type: GET_CUSTOMER_DETAILS,
                    payload: response?.data?.data
                });
                enqueueSnackbar(response?.data?.data, {
                    variant: 'success',
                    horizontal: 'center'
                });
                HandleCallBack()
                handleClose();
                // dispatch(getCreditCustomer())
            }).catch(error => {

                switch (error?.response?.status) {
                    case 401:
                        dispatch({
                            type: SESSION_EXPIRED,
                        })
                        enqueueSnackbar(error?.response?.data?.message, {
                            variant: 'error',
                            horizontal: 'center'
                        });
                        dispatch(refreshToken(addDirectCreditCustomer(data, router, id, enqueueSnackbar, handleClose, HandleCallBack)))
                        break;
                    case 406:
                        enqueueSnackbar(error?.response?.data?.message, {
                            variant: 'error',
                            horizontal: 'center'
                        });
                        break;
                    case 404:
                        enqueueSnackbar(error?.response?.data?.message, {
                            variant: 'error',
                            horizontal: 'center'
                        });
                        break;
                    case 409:
                        enqueueSnackbar(error?.response?.data?.message, {
                            variant: 'error',
                            horizontal: 'center'
                        });
                        break;
                    case 500:
                        enqueueSnackbar("something went wrong", {
                            variant: 'error',
                            horizontal: 'center'
                        });
                        break;
                    default:
                        enqueueSnackbar("something went wrong", {
                            variant: 'error',
                            horizontal: 'center'
                        });
                }
            })
        } else {
            dispatch({
                type: GET_CUSTOMER_DETAILS,
                payload: data
            });
            router.history.push('/addCreditCustomer');
        }
    }
}

export const getDetailsOfCreditCustomer = (formData, enqueueSnackbar, router) => {
    return (dispatch) => {
        dispatch({
            type: IS_DETAIL_LOADING,
        });
        reposWebV2Api.get('/partner/get_single_customer_detail', {

            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: formData
        }).then(response => {
            dispatch({
                type: GET_CUSTOMER_DETAILS,
                payload: response?.data?.data
            });
            enqueueSnackbar('User Exists On Repos Platform', {
                variant: 'success',
                horizontal: 'center'
            });
            router.history.push('/addCustomerDetailPage');

        }).catch(error => {
            if (error?.response?.status === 401) {
                dispatch({
                    type: SESSION_EXPIRED,
                })
                dispatch(refreshToken(getDetailsOfCreditCustomer(formData, enqueueSnackbar, router)))

            } else if (error?.response?.status === 404) {
                dispatch({
                    type: ERROR_CALL,
                    payload: 'not_found'
                })
                enqueueSnackbar(error?.response?.data?.data?.message, {
                    variant: 'info',
                    horizontal: 'center'
                });
            } else if (error?.response?.status === 400) {
                dispatch({
                    type: ERROR_CALL,
                    payload: error?.response?.data?.data
                })
                enqueueSnackbar(error?.response?.data?.data?.message, {
                    variant: 'error',
                    horizontal: 'center'
                });
            } else {
                dispatch({
                    type: ERROR_CALL,
                    payload: 'something went wrong'
                })
                enqueueSnackbar('something went wrong', {
                    variant: 'error',
                    horizontal: 'center'
                });
            }
        })
    }
};

export const clear_error = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ERROR,
        })
    }
}

export const setDetailsOfCreditCustomer = (data) => {
    return (dispatch) => {
        dispatch({
            type: GET_CUSTOMER_DETAILS,
            payload: data
        })
    }
};

export const addCreditCustomer = (formData, enqueueSnackbar, router) => {
    return (dispatch) => {
        dispatch({
            type: IS_CUSTOMER_LOADING,
        });
        reposWebV2Api.post('/partner/credit_customer_for_partner', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            dispatch({
                type: CREATE_CREDIT_CUSTOMER,
                payload: response?.data?.data
            });
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
            router.push({
                pathname: `/customer`,
            })
            // dispatch(getCreditCustomer())
        })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    dispatch(refreshToken(addCreditCustomer(formData, enqueueSnackbar, router)))
                } else if (error?.response?.status === 400) {
                    dispatch({
                        type: ASSET_NOT_CREATED,
                        payload: error?.response?.data?.data
                    })
                    enqueueSnackbar("Customer has no Asset created!", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                    router("/addCreditCustomer")
                } else if (error?.response?.status === 500) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                }
            })
    }
};

export const updateCreditCustomer = (formData, enqueueSnackbar, router) => {
    return (dispatch) => {
        dispatch({
            type: IS_CUSTOMER_LOADING,
        });
        reposWebApi.post('/partner/edit_credit_customer', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            dispatch({
                type: UPDATE_CREDIT_CUSTOMER,
                payload: response?.data?.data
            });
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });

            router.push({
                pathname: `/customer/editCreditCustomer`,
            })

            // dispatch(getCreditCustomer())

        })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    dispatch(refreshToken(updateCreditCustomer(formData, enqueueSnackbar, router)))
                } else if (error?.response?.status === 500) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                }
            })
    }
};


export const deleteCreditCustomer = (customer_id, enqueueSnackbar, router) => {
    const formData = {
        customer_id: customer_id
    }
    return (dispatch) => {
        dispatch({
            type: DELETE_CUSTOMER_LOADING,
        });
        reposWebApi.post('/partner/delete_credit_customer ', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        }).then(response => {
            dispatch({
                type: DELETE_CREDIT_CUSTOMER,
                payload: response?.data?.data
            });
            enqueueSnackbar(response?.data?.data, {
                variant: 'success',
                horizontal: 'center'
            });
            dispatch(getCreditCustomer())
            router.history.push('/customer');

        })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })

                    dispatch(refreshToken(deleteCreditCustomer(customer_id, enqueueSnackbar, router)))
                } else if (error?.response?.status === 500) {
                    enqueueSnackbar("something went wrong", {
                        variant: 'error',
                        horizontal: 'center'
                    });
                }
            })
    }
}

export const getSingleCreditCustomer = (formData, param) => {
    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        dispatch({
            type: IS_CUSTOMER_LOADING,
        });
        reposWebV2Api.get(param, {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: formData
        }).then(response => {
            dispatch({
                type: GET_CUSTOMER_DETAILS,
                payload: response?.data?.data
            });

        })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    dispatch(refreshToken(getSingleCreditCustomer(formData)))
                }
            })
    }
};

export const creditLoading = () => {
    return (dispatch) => {
        dispatch({
            type: IS_CUSTOMER_LOADING,
        })
    }
}

export const saveCustomerData = (data) => {
    return (dispatch) => {
        dispatch({
            type: SAVE_DETAIL,
            payload: data
        })
    }
}

export const clear_Data = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DATA,
        })
    }
}




