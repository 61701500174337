import React, {useEffect} from 'react';
import {matchPath, useLocation} from 'react-router-dom';
// Utilities
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';

// Material Components
import {Box, Drawer, Hidden, List} from '@mui/material';
import HelpIcon from "../../../../assets/svgs/HelpIcon";
import Payment from "../../../../assets/svgs/Payment";
import ProfileSVG from "../../../../assets/svgs/ProfileSVG";
import AccountSetting from "../../../../assets/svgs/AccountSetting"

import NavItem from './NavItem';

// Custom SVGs
import HomeIcon from '../../../../assets/svgs/HomeIcon';
import MyOrders from '../../../../assets/svgs/MyOrders';
import {useSelector} from "react-redux";
import Referral from '../../../../assets/svgs/Referralicon';


const sectionsMenu = [
    {
        items: [
            {
                title: 'Home',
                icon: () => (
                    <HomeIcon style={{paddingRight: "10%"}}/>
                ),
                href: '/dashboard'
            },
            {
                title: 'Orders',
                icon: () => (
                    <MyOrders style={{paddingRight: "10%"}}/>
                ),
                href: '/orders'
            },
            // {
            //     title: 'Pumps',
            //     icon: () => (
            //         <CustomerIcon style={{paddingRight: "5%"}}/>
            //     ),
            //     href: '/pump'
            // },
            // {
            //     title: 'Customer',
            //     icon: () => (
            //         <CustomerIcon style={{paddingRight: "5%"}}/>
            //     ),
            //     href: '/customers'
            // },
            // {
            //     title: 'RPP',
            //     icon: () => (
            //         <RppIcon style={{paddingRight: "7%"}}/>
            //     ),
            //     href: '/rpp'
            // },
            {
                title: "Payments",
                icon: () => (
                    <Payment style={{paddingRight: "10%"}}/>
                ),
                href: '/payments'
            }
        ]
    }
]

const sections = [
    {
        items: [
            {
                title: 'Profile',
                icon: () => (
                    <ProfileSVG style={{paddingRight: "10%"}}/>
                ),
                href: '/Profile'
            },
            {
                title: 'Account Settings',
                icon: () => (
                    <AccountSetting style={{paddingRight: "10%"}}/>
                ),
                href: '/accountsetting'
            },
            // {
            //     title: "Notification",
            //     icon: () => (
            //         <Setting style={{paddingRight: "10%"}}/>
            //     ),
            //     href: '/notifications'
            // },
            {
                title: 'Help',
                icon: () => (
                    <HelpIcon style={{paddingRight: "10%"}}/>
                ),
                href: '/complaint'
            },
            {
                title: 'Bank Account',
                icon: () => (
                    <Payment style={{paddingRight: "10%"}}/>
                ),
                href: '/Bank'
            },
            {
                title: 'Referral',
                icon: () => (
                    <Referral style={{paddingRight: "10%"}}/>
                ),
                href: '/Referral'
            },

        ]
    }
]


const renderNavItems = ({items, pathname, depth = 0, play}) => {


    return (
        <List disablePadding>
            {items.reduce(
                (acc, item) => reduceChildRoutes({acc, item, pathname, depth, play}),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({acc, pathname, item, depth, play}) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
                play={play}
            >
                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                    play: play
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
                play={play}
            />
        );
    }

    return acc;
}

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 300,
        top: 64,
        height: 'calc(100% - 64px)',

    },

}));


const NavBar = ({onMobileClose, openMobile, play}) => {

    const classes = useStyles();
    const location = useLocation();
    const profileMenu = useSelector(state => state?.profileMenu);


    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column" backgroundColor="#f5f5f5">
            <PerfectScrollbar options={{suppressScrollX: true}}>
                <Hidden lgUp>
                    <Box p={2} display="flex" justifyContent="center">
                        {/* <RouterLink to="/">*/}
                        {/*    <Logo/>*/}
                        {/*</RouterLink>*/}
                    </Box>
                </Hidden>
                <Box p={2}>
                </Box>
                {
                    !profileMenu?.Menu ?
                        <Box p={3}>
                            {
                                sections?.map((section, key) => (
                                    <List
                                        key={section.subheader}
                                    >
                                        {
                                            renderNavItems({
                                                items: section.items,
                                                pathname: location.pathname,
                                                play: play
                                            })
                                        }
                                    </List>
                                ))
                            }
                        </Box> :
                        <>
                            <Box p={3}>
                                {
                                    sectionsMenu?.map((section, key) => (
                                        <List
                                            key={section.subheader}
                                        >
                                            {
                                                renderNavItems({
                                                    items: section.items,
                                                    pathname: location.pathname,
                                                    play: play
                                                })
                                            }
                                        </List>
                                    ))
                                }
                            </Box>
                        </>
                }
            </PerfectScrollbar>
        </Box>
    )
    return (
        <>
            {/*<Hidden lgUp>*/}
            {/*    <Drawer*/}
            {/*        anchor="left"*/}
            {/*        classes={{paper: classes.mobileDrawer}}*/}
            {/*        onClose={onMobileClose}*/}
            {/*        open={openMobile}*/}
            {/*        variant="temporary"*/}
            {/*    >*/}
            {/*        {content}*/}
            {/*    </Drawer>*/}
            {/*</Hidden>*/}
            <Hidden lgDown>
                <Drawer
                    anchor="left"
                    classes={{paper: classes.desktopDrawer}}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}
export default NavBar

