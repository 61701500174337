import {reposWebApi} from './customerAPI'
import {refreshToken} from "./sessionAction";
import qs from "querystring";
import {IS_CUSTOMER_LOADING} from "./creditCustomer";

export const IS_LOADING = 'IS_LOADING';
export const CLEAR_ALERTS = 'CLEAR_ALERTS';
export const GET_FUEL_STATION_REPORTS = 'GET_FUEL_STATION_REPORTS';
export const GET_FUEL_STATION_REPORTS_FAIL = 'GET_FUEL_STATION_REPORTS_FAIL';
export const GET_FUEL_STATION_DETAIL = 'GET_FUEL_STATION_DETAIL';
export const GET_REFUELER_STAGE = 'GET_REFUELER_STAGE';
export const CLEAR_RPP_DATA = 'CLEAR_RPP_DATA';
export const RFS_HEALTH_LOADING = 'RFS_HEALTH_LOADING';
export const RFS_HEALTH = 'RFS_HEALTH';
export const GET_GRAPH_DETAIL = 'GET_GRAPH_DETAIL';
export const GET_GRAPH_DETAIL_FAIL = 'GET_GRAPH_DETAIL_FAIL'
export const IS_RPP_STAGE_LOADING = 'IS_RPP_STAGE_LOADING';
export const MAIL_SALES_REPORT = 'MAIL_SALES_REPORT';
export const MAIL_LOADING = "MAIL_LOADING";
export const GET_FUEL_STATION_REPORTS_NO_DATA = 'GET_FUEL_STATION_REPORTS_NO_DATA';

let token = ''
let abortsignal = ''

export const getPartnerRPP = (params, handleSelectRpp, fetchMoreData) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        // dispatch({
        //     type: IS_LOADING,
        // });
        if (!fetchMoreData) {
            dispatch({
                type: IS_CUSTOMER_LOADING,
            });
            dispatch(clear_rpp_data())
        }
        reposWebApi.get('/partner/partner_rpp/list', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params: params
        })
            .then(response => {
                dispatch({
                    type: GET_FUEL_STATION_REPORTS,
                    payload: response?.data.data,
                    // .data.results,
                })
                if (params) {
                    let i;
                    for (i = 0; i < response?.data.data.results.length; i++) {
                        handleSelectRpp(response?.data.data.results[i]);
                    }
                }
            })
            .catch(async error => {
                if (error.response?.status === 401) {
                    await dispatch(refreshToken(getPartnerRPP(params, handleSelectRpp)))
                    dispatch(getFuelStationDetails())
                }
                if (error.response?.status === 500 || error.response?.status === 501 || error.response?.status === 502) {
                    dispatch({
                        type: GET_FUEL_STATION_REPORTS_FAIL,
                        payload: true
                    })
                }
            })
    }
};

export const getFuelStationDetails = (params) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        dispatch({
            type: IS_LOADING,
        });
        reposWebApi.get('/partner/get_fuel_station_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params,
            signal: signal,
        })
            .then(response => {
                dispatch({
                    type: GET_FUEL_STATION_DETAIL,
                    payload: response?.data.data,
                    // .data.results,
                })
                dispatch(getRFSmHealth(params))
            })
            .catch(async error => {
                if (error.response?.status === 401) {
                    await dispatch(refreshToken(getFuelStationDetails(params)))
                } else dispatch({
                    type: GET_FUEL_STATION_REPORTS_FAIL,
                    payload: true
                })
            })
    }
};
export const getGraphDetails = (params, loadChart, rpp) => {

    if (abortsignal) {
        abortsignal.abort();
    }

    abortsignal = new AbortController();
    const signal = abortsignal.signal;

    return (dispatch) => {
        dispatch({
            type: IS_LOADING,
        });
        reposWebApi.get(rpp ? "/partner/rpp_graph_dispense" : "/partner/fuel_station_graph_dispense", {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params,
            signal
        })
            .then(response => {
                dispatch({
                    type: GET_GRAPH_DETAIL,
                    payload: response?.data.data,
                })
                loadChart(response?.data?.data);
            })
            .catch(async error => {
                if (error.response?.status === 401) {
                    await dispatch(refreshToken(getGraphDetails(params, loadChart, rpp)))
                } else dispatch({
                    type: GET_GRAPH_DETAIL_FAIL,
                    payload: true
                })
            })
    }
};

export const getRefuelerStages = (params) => {

    return (dispatch) => {
        dispatch({
            type: IS_RPP_STAGE_LOADING,
        });
        reposWebApi.get('/refueler/list_of_non_onboarded_refueler', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params
        })
            .then(response => {
                dispatch({
                    type: GET_REFUELER_STAGE,
                    payload: response?.data.data,
                    // .data.results,
                })
            })
            .catch(async error => {
                if (error.response?.status === 401) {

                    await dispatch(refreshToken(getRefuelerStages(params)))

                } else dispatch({
                    type: GET_FUEL_STATION_REPORTS_FAIL,
                    payload: true
                })
            })
    }
};

export const getRFSmHealth = (param) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        dispatch({
            type: RFS_HEALTH_LOADING,
        });
        reposWebApi.get("refueler/get_refuelers_detail_for_partner", {
            headers: {
                Authorization: "Bearer " + localStorage.access_token
            },
            signal: signal,
            params: param,
        })
            .then((response) => {
                dispatch({
                    type: RFS_HEALTH,
                    payload: response.data.data,
                });
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {

                    await dispatch(refreshToken(getRFSmHealth(param)))

                } else {

                }
            });
    }
};

export const getActiveRFS = (formData, enqueueSnackbar, setLoading, handleClose) => {
    let param = {
        refueler_id: formData?.refueler_id
    }
    return (dispatch) => {
        setLoading(true);
        reposWebApi.post("refueler/activate/deactivate_rpp", qs.stringify(formData), {
            headers: {
                Authorization: "Bearer " + localStorage.access_token
            }
        })
            .then(async (response) => {
                enqueueSnackbar(response?.data?.data?.message, {
                    variant: 'success',
                    vertical: 'top',
                    horizontal: 'right'
                });
                await dispatch(getRFSmHealth(param))
                setLoading(false);
                handleClose();
            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {

                    await dispatch(refreshToken(getActiveRFS(formData, enqueueSnackbar, setLoading, handleClose)))

                } else {

                }
                setLoading(false);
            });
    }
}

export const getMailSalesReport = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: MAIL_LOADING,
        });
        reposWebApi.get("/partner/send_all_order_partner_report", {
            headers: {
                Authorization: "Bearer " + localStorage.access_token
            },
            params: formData,
        })
            .then((response) => {
                dispatch({
                    type: MAIL_SALES_REPORT,
                    payload: response?.data?.data
                })
                enqueueSnackbar(response?.data?.data, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                });

            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {

                    await dispatch(refreshToken(getMailSalesReport(formData, enqueueSnackbar)))

                }
                if (error?.response?.status === 404) {
                    enqueueSnackbar("No data found..!", {
                        variant: 'info', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                    });
                    dispatch({
                        type: GET_FUEL_STATION_REPORTS_NO_DATA,
                    })
                } else {
                    enqueueSnackbar("Something went wrong..!", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                    });
                }
                dispatch({
                    type: GET_FUEL_STATION_REPORTS_FAIL,
                })

            });
    }
}

export const getMailSalesDirectReport = (formData, enqueueSnackbar) => {
    delete formData["report_type"]
    return (dispatch) => {
        dispatch({
            type: MAIL_LOADING,
        });
        reposWebApi.get("/tracker/partner_vehicle_du_dispense", {
            headers: {
                Authorization: "Bearer " + localStorage.access_token
            },
            params: formData
        })
            .then((response) => {
                dispatch({
                    type: MAIL_SALES_REPORT,
                    payload: response?.data?.data
                })
                enqueueSnackbar(response?.data?.data, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                });

            })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getMailSalesDirectReport(enqueueSnackbar)))
                }
                if (error?.response?.status === 404) {
                    enqueueSnackbar("No data found..!", {
                        variant: 'info', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                    });
                } else {
                    enqueueSnackbar("Something went wrong..!", {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        },
                    });
                }
                dispatch({
                    type: GET_FUEL_STATION_REPORTS_FAIL,
                })

            });
    }
}
export const clear_rpp_data = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_RPP_DATA,
        });
    }
}

export const CloseAlerts = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_ALERTS,
        });
    }
}

export const partnerResourceLoading = () => {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING,
        });
    }
}



