import {Link, NavLink} from "react-router-dom";
import {Box, Divider, IconButton, List, ListItem, Typography} from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ProfileSVG from "../../../../assets/svgs/ProfileSVG";
import React from "react";
import {makeStyles} from "@mui/styles";
import Setting from "../../../../assets/svgs/Setting";
import {useDispatch, useSelector} from "react-redux";
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import Logout from "../../../../assets/svgs/Logout";
import {logout} from "../../../../actions";
import Payment from "../../../../assets/svgs/Payment";
import HelpIcon from "../../../../assets/svgs/HelpIcon";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.common.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1)
    },
    logoWrapper: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '63px',
        flexShrink: 0
    },
    logoLink: {
        fontSize: 0
    },
    logoImage: {
        cursor: 'pointer'
    },
    logoDivider: {
        marginBottom: theme.spacing(2)
    },
    profile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 'fit-content'
    },
    avatar: {
        width: '100px',
        height: '100px'
    },
    nameText: {
        marginTop: theme.spacing(2)
    },
    bioText: {},
    profileDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    },
    listSubheader: {
        color: theme.palette.text.secondary
    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F5F9',
            borderLeft: `4px solid #059492`,
            borderRadius: '4px',
            '& $listItemIcon': {
                color: '#059492',
                marginLeft: '-4px'
            }
        },
        '& + &': {
            marginTop: theme.spacing(1)
        }
    },
    listItem2: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#F1F5F9',
            borderLeft: `4px solid #C44A4A`,
            borderRadius: '4px',
            '& $listItemIcon': {
                color: '#C44A4A',
                marginLeft: '-4px'
            }
        },
        '& + &': {
            marginTop: theme.spacing(1)
        }
    },
    activeListItem: {
        borderLeft: `4px solid #059492`,
        borderRadius: '4px',
        '& $listItemText': {
            color: '#059492'
        },
        '& $listItemIcon': {
            color: '#059492',
            marginLeft: '-4px'
        }
    },
    listItemIcon: {
        marginRight: 0
    },
    listItemText: {
        fontWeight: 500,
        color: theme.palette.text.secondary
    },
    listItemText2: {
        fontWeight: 500,
        color: '#C44A4A'
    },
    listDivider: {
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2)
    }
}));

const Sidebar = ({handleClose}) => {
    const session = useSelector(state => state?.session);
    const classes = useStyles();
    const dispatch = useDispatch();

    const logoutUser = () => {
        dispatch(logout());
        window.location.reload();
        handleClose()
    };

    return (
        <nav className={classes.root}>
            <Box mt={4} mb={2} display={"flex"} flexDirection={"row"}>
                <Box m={2}>
                    <Typography color={"#161616"} fontWeight={500} textTransform={"none"}>
                        Profile
                    </Typography>
                </Box>
                <Box ml={2} flexGrow={1}/>
                <Box m={1}>
                    <IconButton onClick={handleClose}>
                        <CloseTwoToneIcon/>
                    </IconButton>
                </Box>
            </Box>
            <Box display={"flex"} flexDirection={"row"} ml={2}>
                <Link to="/" onClick={handleClose}>
                    <img src={session?.detailsOfCustomer?.pump_logo} width={'40px'} alt={'omc'}/>
                </Link>
                <Box ml={2} mt={2}>
                    <Typography color={"#161616"} fontWeight={500} textTransform={"none"}>
                        {session?.detailsOfCustomer?.name}
                    </Typography>
                </Box>
            </Box>
            <Divider className={classes.profileDivider}/>
            <List
                component="div"
                disablePadding
            >
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to="/Profile"
                    // onClick={handleClose}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <ProfileSVG/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="View Profile"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to="/accountsetting"
                    // onClick={handleClose}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Setting/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Account Settings"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to="/complaint"
                    onClick={handleClose}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <HelpIcon/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Help"
                    />
                </ListItem>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem}
                    component={NavLink}
                    to="/Bank"
                    onClick={handleClose}
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Payment/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText}}
                        primary="Bank Account"
                    />
                </ListItem>
                <Divider className={classes.profileDivider}/>
                <ListItem
                    activeClassName={classes.activeListItem}
                    className={classes.listItem2}
                    onClick={logoutUser}
                    to="/Bank"
                >
                    <ListItemIcon className={classes.listItemIcon}>
                        <Logout/>
                    </ListItemIcon>
                    <ListItemText
                        classes={{primary: classes.listItemText2}}
                        primary="Logout"
                    />
                </ListItem>
            </List>
        </nav>
    );

}

export default Sidebar;