import axios from 'axios';

/***** staging ******/
export const reposWebApi = axios.create({
    baseURL: 'https://staging.reposenergy.com/api/web/v1'
});

export const reposWebV2Api = axios.create({
    baseURL: 'https://staging.reposenergy.com/api/web/v2'
});

export const fileUploadServiceApi = axios.create({
    baseURL: "http://13.127.225.8:8003/v1/upload"
});

export const client_id = "JF9QFcSRNGi8BpH1724CmSoSv9MsXxey32BurKLf";
export const client_secret = "FFadtW6F9W95Vcf01MCGkHkIi0a8xBI9auUecCqTTjtsBZ0yAnyFGFwPZ5IsC1KHuawSNrUW87aOr0M8mJEeZEwOW5ptv5oYC5KlM3J6GpKS8j1cQazP8QmHQSLXP51B";


/***** New Server Staging ******/
// export const reposWebApi = axios.create({
//     baseURL: ' https://new-server.reposenergy.com/api/web/v1'
// });

// export const reposWebV2Api = axios.create({
//     baseURL: ' https://new-server.reposenergy.com/api/web/v2'
// });

// export const fileUploadServiceApi = axios.create({
//     baseURL: "http://13.127.225.8:8003/v1/upload"
// });

// export const client_id = "eTcIPfEYSzmruVEoV3TmqwlqWW3QEYTOrdX6cvoh";
// export const client_secret = "836anIBgI8zpORngLyYHJvLMU91mgZexSbhghdn3vbSMULiXvn03yLvJDprKNpxI6v1Qb77eR3b6ydQFnPiJnm7vP5458i1TbxM5CxbHfCuNJHFX9SFB2EORb3gD9kY9";


/***** live ******/

// export const reposWebApi = axios.create({
//     baseURL: 'https://drf.reposenergy.com/api/web/v1'
// });
//
// export const reposWebV2Api = axios.create({
//     baseURL: 'https://drf.reposenergy.com/api/web/v2'
// });
//
// export const fileUploadServiceApi = axios.create({
//     baseURL: 'https://uploads-drf.reposenergy.com/api/customers'
// });
// export const client_secret = 'njExaFjqawQaNSpg6TIJ4cY3QxHXctZumpx81LN2AbBI77XSXb4RDKgrBdVWvl1C5oVL24AFq08l97MPxwUAuFcFAHnSOrrzzC8Q0i4deUdRHUjs9UsOL5McbmNkpAoP';
// export const client_id = 'ret1RBCWi8AErtlxKGYpY8CXsfciFt3Xc0zpYOJS';
// export const agreement = 'https://drf.reposenergy.com/api/web/v1/finserv/agreement/download/agreement.pdf?id=';




export const appVersion = "V.3.3.0";
