import * as actionTypes from "../actions";

var initialState = {
    color: '',
    rateCall: false,
    rateData: {}
};

const rateReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_RATE_LOADING: {
            return {
                ...state,
                is_rateLoading: true
            };
        }
        case actionTypes.RATE_UPDATE: {
            return {
                ...state,
                rateData: action?.payload,
                is_rateLoading: false,
                rateCall: true
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default rateReducer;