import * as actionTypes from '../actions';

var initialState = {
    transactions: [],
    transactionsLedger: [],
    Loader: true,
    next: '',
    previous: '',
    count: 0,
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const paymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.TRANSACTION_LOADING: {
            return {
                ...state,
                Loader: true,
            };
        }
        case actionTypes.TRANSACTION_DETAIL_SUCCESS: {
            return {
                ...state,
                Loader: false,
                transactions: getUnique(state?.transactions?.concat(action?.payload?.results), 'customer_id'),
                next: action?.payload?.next,
                count: action?.payload
            };
        }
        case actionTypes.CLEAR_PAYMENT_DATA: {
            return {
                ...state,
                transactions: [],
                transactionsLedger: []
            };
        }
        case actionTypes.PAYMENT_DETAIL: {
            return {
                ...state,
                detailsOfPayment: action.payload,
            }
        }
        case actionTypes.TRANSACTION_DETAIL_LEDGER_SUCCESS: {
            return {
                ...state,
                transactionsLedger: getUnique(state?.transactionsLedger?.concat(action?.payload?.results), 'sequence'),
                Loader: false,
                next: action?.payload?.next,
                count: action?.payload?.count
            }
        }

        default: {
            return state;
        }
    }
};


export default paymentReducer;
