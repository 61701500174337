import React from 'react'
import HelpIcon from "../../../../assets/svgs/HelpIcon"
import {IconButton, SvgIcon, Tooltip} from '@mui/material'
import {Link as RouterLink} from "react-router-dom";

const Help = () => {
    return (
        <>
            <Tooltip title="Notifications">
                <IconButton color="inherit" component={RouterLink} to={"/complaint"}>
                    <SvgIcon>
                        <HelpIcon/>
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default Help