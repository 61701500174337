import * as actionTypes from '../actions';

var initialState = {
    refuellers: [],
    isLoading: true,
    convCharges: '',
    orderSuccess: '',
    orderFail: '',
    allOrders: [],
    getOrderError: '',
    count: '',
    next: '',
    previous: '',
    singleOrder: [],
    pastOrder: [],
    overdueOrder: [],
    ongoingOrder: [],
    directModeCustomers: [],
    directModeCustomerCount: 0,
    refuelerCount: '',
    sessionExpired: false,
    orderAlreadyPlaced: false,
    customerDetails: [],
};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}


const orderReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_REFUELERS: {
            return {
                ...state,
                refuellers: action.payload,
                isLoading: false,
                refuelerCount: action.payload.refuelers.length,
                sessionExpired: false,
            };
        }
        case actionTypes.SESSION_EXPIRED: {
            return {
                ...state,
                sessionExpired: true
            };
        }
        case actionTypes.ORDER_LOADING: {
            return {
                ...state,
                isLoading: true,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_CONVENIENCE: {
            return {
                ...state,
                convCharges: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.ORDER_ALREADY_PRESENT: {
            return {
                ...state,
                convCharges: null,
                orderAlreadyPlaced: true,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.PLACE_ORDER: {
            return {
                ...state,
                orderSuccess: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.PLACE_ORDER_FAIL: {
            return {
                ...state,
                orderFail: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_ALL_ORDERS: {
            return {
                ...state,
                allOrders: getUnique(state.allOrders.concat(action?.payload?.results), 'label_order_id'),
                count: action.payload.count,
                next: action.payload.next,
                previous: action.payload.previous,
                isLoading: false,
                sessionExpired: false,
                apiCall: true

            };
        }
        case actionTypes.GET_ALL_ORDERS_FAIL: {
            return {
                ...state,
                getOrderError: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_SINGLE_ORDER: {
            return {
                ...state,
                singleOrder: action.payload,
                sessionExpired: false,
                isLoading: false,

            };
        }
        case actionTypes.GET_PAST_ORDER: {
            return {
                ...state,
                pastOrder: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_ONGOING_ORDER: {
            return {
                ...state,
                // ongoingOrder: action.payload===undefined? 0: action.payload ,
                ongoingOrder: action.payload,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_ALL_CUSTOMER: {
            return {
                ...state,
                directModeCustomers: action.payload?.data?.results?.concat({id: 0, company_name: "Others"}),
                directModeCustomerCount: action.payload.count,
                next: action.payload.next,
                previous: action.payload.previous,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_CUSTOMER: {

            return {
                ...state,
                customerDetails: action.payload?.data,
                isLoading: false,
                sessionExpired: false,
            };
        }
        case actionTypes.GET_OVERDUE_ORDER: {
            return {
                ...state,
                // overdueOrder: action.payload===undefined? 0: action.payload,
                overdueOrder: action.payload,
                isLoading: false,
                sessionExpired: false,

            };
        }
        case actionTypes.GET_CLEAR_ORDER: {
            return {
                ...state,
                allOrders: [],
                getOrderError: '',
                count: '',
                next: '',
                previous: '',
                singleOrder: [],
                pastOrder: [],
                overdueOrder: [],
                ongoingOrder: [],
            }
        }
        default: {
            return state;
        }
    }
};

export default orderReducer;
