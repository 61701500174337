import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const Referral = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);
    return (
        <svg
            width="22"
            height="20"
            viewBox="0 0 20 22"
            fill="none"
            className={className}
            style={style}
            xmlns="http://www.w3.org/2000/svg">
            <path
             d="M4.1155 21.5V10.6152H2.5V5.40398H7.67125C7.53025 5.24114 7.43442 5.05773 7.38375 4.85373C7.33308 4.64989 7.30775 4.43581 7.30775 4.21148C7.30775 3.46364 7.5695 2.82798 8.093 2.30448C8.6165 1.78098 9.25217 1.51923 10 1.51923C10.3833 1.51923 10.7385 1.59964 11.0655 1.76048C11.3923 1.92148 11.6846 2.13656 11.9423 2.40573C12.1999 2.12623 12.4923 1.90864 12.8193 1.75298C13.1461 1.59714 13.5012 1.51923 13.8845 1.51923C14.6325 1.51923 15.2682 1.78098 15.7917 2.30448C16.3152 2.82798 16.577 3.46364 16.577 4.21148C16.577 4.43331 16.5491 4.64423 16.4932 4.84423C16.4374 5.04423 16.3442 5.23081 16.2135 5.40398H21.5V10.6152H19.8845V21.5H4.1155ZM13.8845 3.01923C13.5467 3.01923 13.2635 3.13348 13.035 3.36198C12.8065 3.59048 12.6923 3.87364 12.6923 4.21148C12.6923 4.54931 12.8065 4.83248 13.035 5.06098C13.2635 5.28964 13.5467 5.40398 13.8845 5.40398C14.2223 5.40398 14.5056 5.28964 14.7343 5.06098C14.9628 4.83248 15.077 4.54931 15.077 4.21148C15.077 3.87364 14.9628 3.59048 14.7343 3.36198C14.5056 3.13348 14.2223 3.01923 13.8845 3.01923ZM8.80775 4.21148C8.80775 4.54931 8.922 4.83248 9.1505 5.06098C9.379 5.28964 9.66217 5.40398 10 5.40398C10.3378 5.40398 10.621 5.28964 10.8495 5.06098C11.078 4.83248 11.1923 4.54931 11.1923 4.21148C11.1923 3.87364 11.078 3.59048 10.8495 3.36198C10.621 3.13348 10.3378 3.01923 10 3.01923C9.66217 3.01923 9.379 3.13348 9.1505 3.36198C8.922 3.59048 8.80775 3.87364 8.80775 4.21148ZM4 6.90373V9.11548H11.25V6.90373H4ZM11.25 20V10.6152H5.61525V20H11.25ZM12.75 20H18.3848V10.6152H12.75V20ZM20 9.11548V6.90373H12.75V9.11548H20Z" 
                fill={window.color === 'Referral' ? "#059492" : "#737373"}/>
        </svg>
    )
}


Referral.propTypes = {
    fill: PropTypes.string
};

export default Referral
