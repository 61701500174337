import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const Setting = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);

    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
             className={className}
             style={style}
        >
            <path
                d="M4 19V17.5H6V9.9C6 8.5 6.4125 7.24583 7.2375 6.1375C8.0625 5.02917 9.15 4.33333 10.5 4.05V3.5C10.5 3.08333 10.6458 2.72917 10.9375 2.4375C11.2292 2.14583 11.5833 2 12 2C12.4167 2 12.7708 2.14583 13.0625 2.4375C13.3542 2.72917 13.5 3.08333 13.5 3.5V4.05C14.85 4.33333 15.9375 5.02917 16.7625 6.1375C17.5875 7.24583 18 8.5 18 9.9V17.5H20V19H4ZM12 22C11.45 22 10.9792 21.8042 10.5875 21.4125C10.1958 21.0208 10 20.55 10 20H14C14 20.55 13.8042 21.0208 13.4125 21.4125C13.0208 21.8042 12.55 22 12 22ZM7.5 17.5H16.5V9.9C16.5 8.65 16.0625 7.5875 15.1875 6.7125C14.3125 5.8375 13.25 5.4 12 5.4C10.75 5.4 9.6875 5.8375 8.8125 6.7125C7.9375 7.5875 7.5 8.65 7.5 9.9V17.5Z"
                fill={window.color === 'Notification' ? "#059492" : "#737373"}/>
        </svg>


    )
}


Setting.propTypes = {
    fill: PropTypes.string
};

export default Setting
