import React from 'react'
import {Box, Button, Divider, Typography} from '@mui/material'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Setting from "../../../../assets/svgs/Setting";
import HelpIcon from "../../../../assets/svgs/HelpIcon";
import Payment from "../../../../assets/svgs/Payment";
import Logout from "../../../../assets/svgs/Logout";
import ProfileSVG from "../../../../assets/svgs/ProfileSVG";
import {layoutAction, logout} from "../../../../actions";
import {useDispatch, useSelector} from "react-redux";
import {Link as RouterLink} from "react-router-dom";
import Referral from '../../../../assets/svgs/Referralicon';


const Profile = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const session = useSelector(state => state?.session);

    const open = Boolean(anchorEl);
    const dispatch = useDispatch();
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logoutUser = () => {
        dispatch(logout());
        // window.location.reload();
    };

    const handleLayoutChange = () => {
        dispatch(layoutAction(true))
        handleClose()
    }

    return (
        <>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Box mr={2}>
                    <img src={session?.detailsOfCustomer?.pump_logo} width={'40px'} alt={'omc'}/>
                    {/*<ProfileIcon/>*/}
                </Box>
                <Box>
                    <Typography color={"#161616"} fontWeight={500} textTransform={"none"}>
                        {session?.detailsOfCustomer?.name}
                    </Typography>
                </Box>
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuList>
                    <MenuItem component={RouterLink} to={"/Profile"} onClick={handleLayoutChange}>
                        <ListItemIcon>
                            <ProfileSVG/>
                        </ListItemIcon>
                        <ListItemText>View Profile</ListItemText>
                    </MenuItem>
                    <MenuItem component={RouterLink} to={"/accountsetting"} onClick={handleLayoutChange}>
                        <ListItemIcon>
                            <Setting/>
                        </ListItemIcon>
                        <ListItemText>Account Settings</ListItemText>
                    </MenuItem>
                    <MenuItem component={RouterLink} to={"/complaint"} onClick={handleLayoutChange}>
                        <ListItemIcon>
                            <HelpIcon/>
                        </ListItemIcon>
                        <ListItemText>Help</ListItemText>
                    </MenuItem>
                    <MenuItem component={RouterLink} to={"/Bank"} onClick={handleLayoutChange}>
                        <ListItemIcon>
                            <Payment/>
                        </ListItemIcon>
                        <ListItemText>Bank Account</ListItemText>
                    </MenuItem>
                    <MenuItem component={RouterLink} to={"/Referral"} onClick={handleLayoutChange}>
                        <ListItemIcon>
                            <Referral/>
                        </ListItemIcon>
                        <ListItemText>Referral</ListItemText>
                    </MenuItem>
                    <Divider sx={{borderStyle: 'dashed'}}/>
                    <MenuItem onClick={logoutUser}>
                        <ListItemIcon>
                            <Logout/>
                        </ListItemIcon>
                        <ListItemText style={{
                            color: "#C44A4A",
                            fontWeight: "500"
                        }}>LogOut</ListItemText>
                    </MenuItem>
                </MenuList>
            </Menu>

        </>
    )
}

export default Profile