import {reposWebApi} from "./customerAPI";
import {refreshToken} from "./sessionAction";

export const RATE_UPDATE = 'RATE_UPDATE';
export const IS_RATE_LOADING = 'IS_RATE_LOADING';

export const getRateUpdate = (requestBody, enqueueSnackbar, setNoti) => {

    return (dispatch) => {
        dispatch({
            type: IS_RATE_LOADING,
        });
        reposWebApi.post('/partner/refueler_rate_density_update', requestBody, {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: RATE_UPDATE,
                    payload: response?.data.data,
                    // .data.results,
                })
                enqueueSnackbar(response?.data.data, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    },
                    preventDuplicate: true
                });
                setNoti(true);
            })
            .catch(async error => {
                if (error.response?.status === 401) {
                    await dispatch(refreshToken(getRateUpdate()))
                }
            })
    }
};




