import React from 'react';
import {NavLink as RouterLink} from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {ListItem} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {colorIcon} from "../../../../actions";
import {useDispatch} from "react-redux";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

const useStyles = makeStyles((theme) => ({
    item: {
        display: 'block',
        paddingTop: 0,
        paddingBottom: 0
    },
    itemLeaf: {
        display: 'flex',
        paddingTop: 0,
        paddingBottom: 0
    },
    button: {
        color: theme.palette.text.secondary,
        padding: '10px 8px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        width: '100%'
    },
    listItem: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#e9eaf1',
            borderLeft: `4px solid #059492`,
            borderRadius: '4px',
            '& $listItemIcon': {
                color: '#059492',
                marginLeft: '-4px'
            }
        },
        '& + &': {
            marginTop: theme.spacing(1)
        },
        marginBottom: '10px'
    },
    icon: {
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },
    title: {
        marginRight: 'auto',
        fontSize: '15px'
    },
    listItemIcon: {
        marginRight: 0
    },
    listItemText: {
        fontWeight: 500,
        color: theme.palette.text.secondary
    },
    activeListItem: {
        backgroundColor: '#d2d5d9',
        borderLeft: `4px solid #059492`,
        borderRadius: '4px',
        '& $listItemText': {
            color: '#059492'
        },
        '& $listItemIcon': {
            color: '#059492',
            marginLeft: '-4px'
        }
    },
}));


const NavItem = ({
                     children,
                     className,
                     depth,
                     href,
                     icon: Icon,
                     info: Info,
                     open: openProp,
                     title,
                     play,
                     ...rest
                 }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    let paddingLeft = 8;

    if (depth > 0) {
        paddingLeft = 32 + 8 * depth;
    }

    const style = {paddingLeft};

    const handleChangesIconColor = () => {
        dispatch(colorIcon(title));
    }

    return (
        <ListItem
            className={clsx(classes.itemLeaf, className)}
            disableGutters
            key={title}
            {...rest}
        >

            <ListItem
                activeClassName={classes.activeListItem}
                className={clsx(classes.listItem, `depth-${depth}`)}
                component={RouterLink}
                onClick={handleChangesIconColor}
                onClickCapture={play}
                exact
                style={style}
                to={href}
            >
                <ListItemIcon className={classes.listItemIcon}>
                    {Icon && (
                        <Icon
                            className={classes.icon}
                            size="20"
                            colorCode={"#161616"}
                        />
                    )}
                </ListItemIcon>
                <ListItemText
                    classes={{primary: classes.listItemText}}
                    primary={title}
                />
                {/*<span className={classes.title}> {title} </span>*/}

            </ListItem>
        </ListItem>
    );
};

NavItem.propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    depth: PropTypes.number.isRequired,
    href: PropTypes.string,
    icon: PropTypes.elementType,
    info: PropTypes.elementType,
    open: PropTypes.bool,
    title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
    open: false
};

export default NavItem;
