import React, {Fragment, Suspense} from 'react';
//import { renderRoutes } from 'react-router-config';
import {renderRoutes} from '../../Routes'
import PropTypes from 'prop-types';
import {makeStyles} from '@mui/styles';
import SplashScreen from "../../components/SplashScreen";

const useStyles = makeStyles(theme => ({
    content: {
        height: '100%',
        paddingTop: 56,
        [theme.breakpoints.up('sm')]: {
            paddingTop: 64
        }
    }
}));

const Auth = props => {
    const {route} = props;

    const classes = useStyles();

    return (
        <Fragment>
            <main className={classes.content}>
                <Suspense fallback={<SplashScreen/>}>
                    {renderRoutes(route.routes)}
                </Suspense>
            </main>
        </Fragment>
    );
};

Auth.propTypes = {
    route: PropTypes.object
};

export default Auth;
