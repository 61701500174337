import * as actionTypes from "../actions";

var initialState = {
    Menu: true
};

const sideBarReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_PROFILE_MENU: {
            return {
                ...state,
                Menu: action?.payload,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default sideBarReducer;