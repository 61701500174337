import {reposWebApi} from './customerAPI'
import {refreshToken} from "./sessionAction";

export const GET_DISPENSE_REPORTS = 'GET_DISPENSE_REPORTS';
export const IS_DISPENSE_LOADING = 'IS_DISPENSE_LOADING'
export const GET_DISPENSE_DIRECT_REPORTS = 'GET_DISPENSE_DIRECT_REPORTS'
export const IS_REPORT = 'IS_REPORT';
export const CLEAR_DISPENSE_DATA = 'CLEAR_DISPENSE_DATA';

let token = ''

export const getDispense = (params, fetchMoreData) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: IS_DISPENSE_LOADING,
            });
            dispatch(clear_Dispense_Data())
        }

        reposWebApi.get('/partner/get_partner_dispense_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params
        }).then(response => {
            dispatch({
                type: GET_DISPENSE_REPORTS,
                payload: response?.data.data,
            })
        })
            .catch(async error => {
                if (error.response?.status === 401) {
                    await dispatch(refreshToken(getDispense(params, fetchMoreData)))
                }
            })
    }
};

export const getDispenseDirect = (params, fetchMoreData) => {

    if (token) {
        token.abort();
    }

    token = new AbortController();
    const signal = token.signal;

    return async (dispatch) => {
        if (!fetchMoreData) {
            dispatch({
                type: IS_DISPENSE_LOADING,
            });
            dispatch(clear_Dispense_Data())
        }

        reposWebApi.get('/partner/du_dispense_direct_mode_order', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            signal: signal,
            params
        }).then(response => {
            dispatch({
                type: GET_DISPENSE_DIRECT_REPORTS,
                payload: response?.data.data,
            })
        })
            .catch(async error => {
                if (error.response?.status === 401) {
                    await dispatch(refreshToken(getDispenseDirect(params, fetchMoreData)))
                }
            })
    }
};

export const getDispenseLoad = () => {
    return (dispatch) => {
        dispatch({
            type: IS_REPORT,
        });
    }
}

export const clear_Dispense_Data = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAR_DISPENSE_DATA,
        })
    }
}
