import {combineReducers} from 'redux';
import sessionReducer from './sessionReducer';
import orderReducer from './orderReducer'
import reponsiveReducer from "./reponsiveReducer";
import getPartnerStationReducer from "./getPartnerStationReducer";
import rateReducer from "./rateReducer";
import profileReducer from "./profileReducer";
import sideBarReducer from "./sideBarReducer";
import paymentReducer from "./paymentReducer";
import dispenseReducer from "./dispenseReducer";
import creditCustomerReducer from "./creditCustomer";
import notificationReducer from "./notificationReducer";

const rootReducer = combineReducers({
    session: sessionReducer,
    getOrder: orderReducer,
    dispenseReport: dispenseReducer,
    getWindowSize: reponsiveReducer,
    getFuelStation: getPartnerStationReducer,
    getUpateRate: rateReducer,
    getProfile: profileReducer,
    profileMenu: sideBarReducer,
    getPayment: paymentReducer,
    getCustomer: creditCustomerReducer,
    getNotify: notificationReducer
});

export default rootReducer;