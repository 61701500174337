import * as actionTypes from "../actions";

var initialState = {
    windowSize: '',
    color: ''
};

const reponsiveReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.WINDOW_SIZE: {
            return {
                ...state,
                windowSize: action?.payload === undefined ? 'md' : action?.payload,
            };
        }
        case actionTypes.LOGIN_WINDOW_SIZE: {
            return {
                ...state,
                windowSize: action?.payload === undefined ? 'gt' : action?.payload,
            };
        }
        case actionTypes.GET_COLOR: {
            return {
                ...state,
                color: action?.payload,
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default reponsiveReducer;