import * as actionTypes from '../actions';

var initialState = {
    updateSuccessful: false,
    isLoading: true,
    profileDetails: null,
    changePasswordmsgSent: null,
    complainList: [],
    complaintDetail: {},
    bankDetails: null,
    category: '',
    subCategory: '',
    assetList: '',
    rfs: '',
    next: '',
    count: 0

};

function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

const profileReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.UPDATE_PROFILE_SUCCESS: {
            return {
                ...state,
                updateSuccessful: true,
            };
        }
        case actionTypes.UPDATE_PROFILE_ERROR: {
            return {
                ...state,
                updateSuccessful: false
            };
        }
        case actionTypes.PROFILE_DETAILS_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                profileDetails: action?.payload
            }
        }
        case actionTypes.CHANGE_PASSWORD_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                changePasswordMsgSent: action?.payload,
            };
        }
        case actionTypes.CHANGE_PASSWORD_FAIL: {
            return {
                ...state,
                isLoading: false,
                changePasswordMsgSent: action?.payload
            };
        }
        case actionTypes.CHANGE_PASSWORD_LOADING: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case actionTypes.LOADING_FALSE: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case actionTypes.PROFILE_LOADING: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case actionTypes.GET_BANK_DETAILS: {
            return {
                ...state,
                isLoading: false,
                bankDetails: action?.payload
            }
        }
        case actionTypes.BANK_DETAILS_LOADING: {
            return {
                ...state,
                isLoading: true,
            }
        }
        case actionTypes.GET_BANK_DETAILS_FAIL: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case actionTypes.UPDATE_BANK_SUCCESS: {
            return {
                ...state,
                isLoading: false
            }
        }

        case actionTypes.UPDATE_BANK_ERROR: {
            return {
                ...state,
                isLoading: false
            }
        }
        case actionTypes.COMPLAINT_LOADING: {
            return {
                ...state,
                isLoading: true
            }
        }
        case actionTypes.GET_RFS: {
            return {
                ...state,
                isLoading: false,
                rfs: action?.payload
            }
        }
        case actionTypes.GET_ISSUSE_CATEGORY: {
            return {
                ...state,
                isLoading: false,
                category: action?.payload
            }
        }
        case actionTypes.GET_SUB_CATEGORY: {
            return {
                ...state,
                isLoading: false,
                subCategory: action?.payload
            }
        }
        case actionTypes.GET_COMPLAINT_DETAIL: {
            return {
                ...state,
                isLoading: false,
                complaintDetail: action?.payload
            }
        }
        case actionTypes.GET_COMPLAINT_DETAIL_FAIL: {
            return {
                ...state,
                isLoading: false,
            }
        }
        case actionTypes.GET_COMPLAINT_LIST: {
            return {
                ...state,
                complainList: getUnique(state?.complainList.concat(action?.payload?.results), 'ticket_id'),
                isLoading: false,
                next: action?.payload?.next,
                count: action?.payload?.results?.length
            }
        }
        case actionTypes.GET_COMPLAINT_LIST_FAIL: {
            return {
                ...state,
                isLoading: false,
            }
        }
        default: {
            return state;
        }
    }
};


export default profileReducer;
