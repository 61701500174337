import * as actionTypes from "../actions";

var initialState = {
    is_Loading: true,
    is_details_Loading: true,
    Data: [],
    singleCustomer: {},
    next: null,
    previous: null,
    create: {},
    edit: {},
    count: 0,
    errorMsg: '',
    loader: true
};

/************** We need to do it later **************/
function getUnique(array, key) {
    if (typeof key !== 'function') {
        const property = key;
        key = function (item) {
            return item[property];
        };
    }
    return Array.from(array.reduce(function (map, item) {
        const k = key(item);
        if (!map.has(k)) map.set(k, item);
        return map;
    }, new Map()).values());
}

/************** ****************** **************/

const creditCustomerReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.IS_CUSTOMER_LOADING: {
            return {
                ...state,
                is_Loading: true
            };
        }
        case actionTypes.CLEAR_DATA: {
            return {
                Data: [],
                singleCustomer: {},
                create: {},
                is_Loading: true
            }
        }
        case actionTypes.IS_DETAIL_LOADING: {
            return {
                ...state,
                is_details_Loading: true
            };
        }
        case actionTypes.GET_CREDIT_CUSTOMER: {
            return {
                ...state,
                is_Loading: false,
                Data: getUnique(state?.Data?.concat(action?.payload?.results), "customer_id"),
                next: action?.payload?.next,
                previous: action?.payload?.previous,
                count: action?.payload?.count,
            };

        }

        case actionTypes.GET_CREDIT_DIRECT_CUSTOMER: {
            return {
                ...state,
                is_Loading: false,
                Data: state?.Data?.concat(action?.payload?.results),
                next: action?.payload?.next,
                previous: action?.payload?.previous,
                count: action?.payload?.count,
            };

        }

        case actionTypes.ASSET_NOT_CREATED: {
            return {
                ...state,
                is_Loading: false,
                errorMsg: action?.payload
            }
        }
        case actionTypes.GET_CUSTOMER_DETAILS: {
            return {
                ...state,
                is_Loading: false,
                singleCustomer: action?.payload,
                next: action?.payload?.next,
                previous: action?.payload?.previous,
                count: action?.payload?.count,
                errorMsg: '',
                is_details_Loading: false
            };
        }
        case actionTypes.SAVE_DETAIL: {
            return {
                ...state,
                savedData: action?.payload,
            }
        }
        case actionTypes.ERROR_CALL: {
            return {
                ...state,
                is_Loading: false,
                errorMsg: action?.payload,
                is_details_Loading: false
            };
        }
        case actionTypes.CLEAR_ERROR: {

            return {
                ...state,
                errorMsg: ''
            }
        }
        case actionTypes.CREATE_CREDIT_CUSTOMER: {
            return {
                ...state,
                create: action?.payload,
                next: action?.payload?.next,
                previous: action?.payload?.previous,
                count: action?.payload?.count,
                is_Loading: false,
            };
        }
        case actionTypes.UPDATE_CREDIT_CUSTOMER: {
            return {
                ...state,
                edit: action?.payload,
                next: action?.payload?.next,
                previous: action?.payload?.previous,
                count: action?.payload?.count,
                is_Loading: false
            };
        }
        default: {
            return {
                ...state,
            };
        }
    }
};


export default creditCustomerReducer;