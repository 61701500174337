import qs from "querystring";
import {reposWebApi, reposWebV2Api} from './customerAPI'
import {refreshToken, SESSION_EXPIRED} from "./sessionAction";

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'UPDATE_PROFILE_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAIL = 'CHANGE_PASSWORD_FAIL';
export const PROFILE_LOADING = 'PROFILE_LOADING';
export const PROFILE_DETAILS_SUCCESS = 'PROFILE_DETAILS_SUCCESS';
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING';
export const GET_BANK_DETAILS_FAIL = 'GET_BANK_DETAILS_FAIL';
export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';
export const BANK_DETAILS_LOADING = 'BANK_DETAILS_LOADING';
export const UPDATE_BANK_ERROR = 'UPDATE_BANK_ERROR';
export const UPDATE_BANK_SUCCESS = 'UPDATE_BANK_SUCCESS';
export const RAISE_COMPLAINT_SUCCESS = 'RAISE_COMPLAINT_SUCCESS';
export const RAISE_COMPLAINT_FAIL = 'RAISE_COMPLAINT_FAIL';
export const GET_ISSUSE_CATEGORY = 'GET_ISSUSE_CATEGORY';
export const GET_ISSUSE_CATEGORY_FAIL = 'GET_ISSUSE_CATEGORY_FAIL';
export const GET_SUB_CATEGORY = 'GET_SUB_CATEGORY';
export const GET_SUB_CATEGORY_FAIL = 'GET_SUB_CATEGORY_FAIL';
export const GET_RFS_FAIL = 'GET_RFS_FAIL';
export const GET_RFS = 'GET_RFS';
export const COMPLAINT_LOADING = 'COMPLAINT_LOADING';
export const GET_COMPLAINT_DETAIL = 'GET_COMPLAINT_DETAIL';
export const GET_COMPLAINT_DETAIL_FAIL = 'GET_COMPLAINT_DETAIL_FAIL';
export const GET_COMPLAINT_LIST = 'GET_COMPLAINT_LIST';
export const GET_COMPLAINT_LIST_FAIL = 'GET_COMPLAINT_LIST_FAIL'


export const updateProfile = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: PROFILE_LOADING,
        });
        reposWebV2Api.post('/partner/update_partner_profile', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: UPDATE_PROFILE_SUCCESS,
                    payload: response?.data?.data
                });

                enqueueSnackbar(response?.data?.data?.message, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });

            })
            .catch(error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                }
                if (error?.response?.status === 406) {
                    alert("No user found");
                    window.location.reload();
                    dispatch({
                        type: UPDATE_PROFILE_ERROR,
                    })
                }
                dispatch({
                    type: UPDATE_PROFILE_ERROR,
                    payload: error
                });
            })
    }
};

export const profileDetails = () => {
    return (dispatch) => {
        dispatch({
            type: PROFILE_LOADING,
        });
        reposWebV2Api.get('/partner/get_partner_profile_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: PROFILE_DETAILS_SUCCESS,
                    payload: response?.data?.data
                });

            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    await dispatch(refreshToken(profileDetails()))
                }
            })
    }
};

export const changePassword = (formData, enqueueSnackbar) => {

    return (dispatch) => {
        dispatch({
            type: CHANGE_PASSWORD_LOADING,
        });
        reposWebApi.post('/partner/change_password', qs.stringify(formData),
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.access_token
                }
            }).then((response) => {
            enqueueSnackbar("Password Changed Successfully", {
                variant: 'success', anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right'
                }
            });
            dispatch({
                type: CHANGE_PASSWORD_SUCCESS,
                payload: response?.data?.data,
            });
        })
            .catch(async (error) => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    });
                    await dispatch(refreshToken(changePassword(formData, enqueueSnackbar)))
                }
                if (error?.response?.status === 404) {
                    enqueueSnackbar('User not found', {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: 'New Password and Re-enter Password are not same ',
                    });
                } else if (error?.response?.status === 406) {
                    enqueueSnackbar('Old password is incorrect', {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: 'Old password is incorrect',
                    });
                } else if (error?.response?.status === 403) {
                    enqueueSnackbar('your new password and re-entered password is mismatched', {
                        variant: 'error', anchorOrigin: {
                            vertical: 'top',
                            horizontal: 'right'
                        }
                    });
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: 'Old password is incorrect',
                    })
                } else {
                    dispatch({
                        type: CHANGE_PASSWORD_FAIL,
                        payload: error,
                    });
                }
            });
    };
};

// --------------- BANK APIs --------------------------
export const updateBank = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: BANK_DETAILS_LOADING,
        });
        reposWebV2Api.post('/partner/update_bank_detail', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: UPDATE_BANK_SUCCESS,
                    payload: response?.data?.data
                });

                enqueueSnackbar(response?.data?.data?.msg, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });
                dispatch(getBankAccountDetails())

            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    await dispatch(refreshToken(getBankAccountDetails(formData, enqueueSnackbar)))
                }
                if (error?.response?.status === 406) {
                    alert("No user found");
                    window.location.reload();
                    dispatch({
                        type: UPDATE_BANK_ERROR,
                    })
                }
                dispatch({
                    type: UPDATE_BANK_ERROR,
                    payload: error
                });
            })
    }
};

export const getBankAccountDetails = () => {
    return (dispatch) => {
        dispatch({
            type: BANK_DETAILS_LOADING,
        })
        reposWebV2Api.get('/partner/get_bank_details', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        })
            .then(response => {
                dispatch({
                    type: GET_BANK_DETAILS,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getBankAccountDetails()))
                } else dispatch({
                    type: GET_BANK_DETAILS_FAIL,
                    payload: error
                })
            })
    }

};

// -------------- raise complaint APIs ---------------------

export const raiseComplaint = (formData, enqueueSnackbar) => {
    return (dispatch) => {
        dispatch({
            type: BANK_DETAILS_LOADING,
        });
        reposWebV2Api.post('/partner/raise_complaint', qs.stringify(formData), {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            }
        })
            .then(response => {
                dispatch({
                    type: RAISE_COMPLAINT_SUCCESS,
                    payload: response?.data?.data
                });

                enqueueSnackbar(response?.data?.data, {
                    variant: 'success', anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'right'
                    }
                });

            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    dispatch({
                        type: SESSION_EXPIRED,
                    })
                    await dispatch(refreshToken(raiseComplaint(formData, enqueueSnackbar)))
                }
                if (error?.response?.status === 406) {
                    alert("No user found");
                    window.location.reload();
                    dispatch({
                        type: RAISE_COMPLAINT_FAIL,
                    })
                }
                dispatch({
                    type: RAISE_COMPLAINT_FAIL,
                    payload: error
                });
            })
    }
};


export const getIssues = () => {
    return (dispatch) => {
        dispatch({
            type: COMPLAINT_LOADING,
        })
        reposWebV2Api.get('/partner/partner_issue_category', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
        })
            .then(response => {
                dispatch({
                    type: GET_ISSUSE_CATEGORY,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getIssues()))
                } else dispatch({
                    type: GET_ISSUSE_CATEGORY_FAIL,
                    payload: error
                })
            })
    }

};

export const getSubComplaint = (category) => {
    return (dispatch) => {
        dispatch({
            type: COMPLAINT_LOADING,
        })
        reposWebV2Api.get('/partner/partner_issue_sub_category', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: {
                category: category
            }
        })
            .then(response => {
                dispatch({
                    type: GET_SUB_CATEGORY,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getIssues(category)))
                } else dispatch({
                    type: GET_SUB_CATEGORY_FAIL,
                    payload: error
                })
            })
    }

};

//  -------------------- complaint detail API ---------------------


export const getComplaintDetail = (ticket_id) => {
    return (dispatch) => {
        dispatch({
            type: COMPLAINT_LOADING,
        })
        reposWebV2Api.get('/partner/get_complaint_detail', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: {
                ticket_id: ticket_id
            }
        })
            .then(response => {
                dispatch({
                    type: GET_COMPLAINT_DETAIL,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getComplaintDetail(ticket_id)))
                } else dispatch({
                    type: GET_COMPLAINT_DETAIL_FAIL,
                    payload: error
                })
            })
    }

};

// ----------------- Get Complaint List API ------------------

export const getComplaintList = (formData) => {
    return (dispatch) => {
        dispatch({
            type: COMPLAINT_LOADING,
        })
        reposWebV2Api.get('/partner/get_complaint_list', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: formData
        })
            .then(response => {
                dispatch({
                    type: GET_COMPLAINT_LIST,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getComplaintList(formData)))
                } else dispatch({
                    type: GET_COMPLAINT_LIST_FAIL,
                    payload: error
                })
            })
    }

};

// --------- select RFS API -------------------------

export const getRFS = (param) => {
    return (dispatch) => {
        dispatch({
            type: COMPLAINT_LOADING,
        })
        reposWebV2Api.get('/partner/rpp_name/list', {
            headers: {
                Authorization: 'Bearer ' + localStorage.access_token
            },
            params: param
        })
            .then(response => {
                dispatch({
                    type: GET_RFS,
                    payload: response?.data?.data
                })
            })
            .catch(async error => {
                if (error?.response?.status === 401) {
                    await dispatch(refreshToken(getIssues(param)))
                } else dispatch({
                    type: GET_RFS_FAIL,
                    payload: error
                })
            })
    }

};

