export const IS_PROFILE_MENU = 'IS_PROFILE_MENU';

export const layoutAction = (isProfileMenu) => {
    let profileMenu;

    if (isProfileMenu) {
        profileMenu = false
    } else {
        profileMenu = true
    }

    return (dispatch) => {
        dispatch({
            type: IS_PROFILE_MENU,
            payload: profileMenu
        });
    }
};





