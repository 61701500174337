import React from 'react'
import PropTypes from 'prop-types';
import {useSelector} from "react-redux";

const MyPayments = ({style, className, ...props}) => {
    const window = useSelector(state => state?.getWindowSize);
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={24}
            height={24}
            fill="none"
            style={style}
            {...props}
        >
            <path
                fill={window.color === 'Payments' ? "#059492" : "#64748B"}
                d="M16.3 13.6c.417 0 .787-.162 1.113-.487.325-.325.487-.7.487-1.125 0-.425-.162-.796-.487-1.113-.326-.317-.696-.475-1.113-.475-.417 0-.788.158-1.113.475-.324.317-.487.688-.487 1.113 0 .425.163.8.488 1.125.324.325.695.487 1.112.487ZM4.5 18.175V19.5v-15 13.675ZM4.5 21c-.383 0-.73-.15-1.038-.45C3.155 20.25 3 19.9 3 19.5v-15c0-.383.154-.73.462-1.038C3.772 3.155 4.117 3 4.5 3h15c.4 0 .75.154 1.05.462.3.309.45.655.45 1.038v3.35h-1.5V4.5h-15v15h15v-3.325H21V19.5c0 .4-.15.75-.45 1.05-.3.3-.65.45-1.05.45h-15Zm8.95-4.325c-.567 0-1.017-.167-1.35-.5-.333-.333-.5-.775-.5-1.325V9.175c0-.567.167-1.013.5-1.338.333-.324.783-.487 1.35-.487h6.75c.567 0 1.017.163 1.35.488.333.324.5.77.5 1.337v5.675c0 .55-.167.992-.5 1.325-.333.333-.783.5-1.35.5h-6.75Zm7.1-1.5V8.85H13.1v6.325h7.45Z"
            />
        </svg>
    )
}

MyPayments.propTypes = {
    fill: PropTypes.string
};

export default MyPayments
