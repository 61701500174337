import React, {Fragment, lazy, Suspense} from 'react';
import {Route, Switch} from 'react-router-dom';
import DashboardLayout from './layouts/DashoboardLayout';
import AuthLayout from './layouts/Auth';
import GuestGuard from "./components/GuestGuard";
import AuthGuard from "./components/AuthGuard";
import SplashScreen from "./components/SplashScreen";
import ErrorBoundary from "./ErrorBoundary";

export const renderRoutes = (routes = []) => (
    <ErrorBoundary>
        <Suspense fallback={<SplashScreen/>}>
            <Switch>
                {routes.map((route, i) => {
                    const Guard = route.guard || Fragment;
                    const Layout = route.layout || Fragment;
                    const Component = route.component;
                    return (<Route
                            key={i}
                            path={route.path}
                            exact={route.exact}
                            render={(props) => (
                                <Guard>
                                    <Layout>
                                        {route.routes ? (
                                            renderRoutes(route.routes)
                                        ) : (
                                            <Component {...props} />
                                        )}
                                    </Layout>
                                </Guard>
                            )}
                        />
                    );
                })}
            </Switch>
        </Suspense>
    </ErrorBoundary>
);

const routes = [
    {
        exact: true,
        guard: GuestGuard,
        path: '/login',
        component: lazy(() => import('./views/Login'))
    },
    {

        path: '/',
        guard: AuthGuard,
        layout: DashboardLayout,
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./views/dashboard')),
            },
            {
                exact: true,
                path: '/dashboard',
                component: lazy(() => import('./views/dashboard')),
            },
            {
                exact: true,
                path: '/customerDetails',
                component: lazy(() => import('./views/customer/components/customerDetailPage/CustomerDetails'))
            },
            {
                exact: true,
                path: '/addCreditCustomer',
                component: lazy(() => import('./views/customer/components/addCustomer/AddCreditCustomer'))
            },
            {
                exact: true,
                path: '/editCreditCustomer',
                component: lazy(() => import('./views/customer/components/addCustomer/AddCreditCustomer'))
            },
            {
                exact: true,
                path: '/customer',
                component: lazy(() => import('./views/customer'))
            },
            {
                exact: true,
                path: '/customer/editCreditCustomer',
                component: lazy(() => import('./views/customer/components/customerDetailPage/EditCustomerInfo'))
            },
            {
                exact: true,
                path: '/addCustomerDetailPage',
                component: lazy(() => import('./views/customer/components/AddCustomerDetailPage'))
            },
            {
                exact: true,
                path: '/addCustomerDirectMode',
                component: lazy(() => import('./views/customer/components/direactModeCustomers/AddDirectModeCust'))
            },
            {
                exact: true,
                path: '/orders',
                component: lazy(() => import('./views/OrdersPage'))
            },
            {
                exact: true,
                path: '/payments',
                component: lazy(() => import('./views/payments'))
            },
            {
                exact: true,
                path: '/insights',
                component: lazy(() => import('./views/insights'))
            },
            {
                exact: true,
                path: '/pump',
                component: lazy(() => import('./views/pumpFlow'))
            },
            {
                exact: true,
                path: '/rate',
                component: lazy(() => import('./views/dashboard/Components/RateAndDensity/RateDensityPage'))
            },
            {
                exact: true,
                path: '/profile',
                component: lazy(() => import('./views/Profile/Profile/ProfileMenu'))
            },
            {
                exact: true,
                path: '/Bank',
                component: lazy(() => import('./views/Profile/Profile/LinkAccount'))
            },
            {
                exact: true,
                path: '/Referral',
                component: lazy(() => import('./views/Profile/Profile/ReferralList'))
            },
            {
                exact: true,
                path: '/addReferral',
                component: lazy(() => import('./views/Profile/Profile/AddReferral'))
            },
            {
                exact: true,
                path: '/accountSetting',
                component: lazy(() => import('./views/Profile/Profile/AccountSetting'))
            },
            {
                exact: true,
                path: '/changePassword',
                component: lazy(() => import('./views/Profile/Profile/ChangePassword'))
            },
            {
                exact: true,
                path: '/notifications',
                component: lazy(() => import('./views/Profile/Profile/Notification'))
            },
            {
                exact: true,
                path: '/complaint',
                component: lazy(() => import('./views/Profile/Profile/Complaint'))
            },
            {
                exact: true,
                path: '/raiseComplaint',
                component: lazy(() => import('./views/Profile/Profile/Complaint/RaiseComplaint'))
            },
            {
                exact: true,
                path: '/complaintDetail',
                component: lazy(() => import('./views/Profile/Profile/Complaint/ComplaintDetail'))
            },
            {
                path: '/auth',
                component: AuthLayout,
                routes: [
                    {
                        path: '/auth/login',
                        exact: true,
                        component: lazy(() => import('./views/Login'))
                    },
                    {
                        path: '/auth/login/otp',
                        exact: true,
                        component: lazy(() => import('./views/LoginViaOtp'))
                    },
                ]
            },
        ]
    },

]

export default routes